import Logger from './logger';

// Store original console methods
const originalConsole = {
  warn: console.warn,
  error: console.error
};

// Configure global error handling
window.onerror = (message, source, lineno, colno, error) => {
  Logger.error('Uncaught error', {
    message,
    source,
    lineno,
    colno,
    error: Logger.formatError(error)
  });
};

// Handle unhandled promise rejections
window.onunhandledrejection = (event) => {
  Logger.error('Unhandled promise rejection', {
    reason: event.reason,
    error: Logger.formatError(event.reason)
  });
};

// Configure console overrides for development
if (process.env.NODE_ENV === 'development') {
  console.warn = (...args) => {
    Logger.warn(args.join(' '));
    originalConsole.warn.apply(console, args);
  };

  console.error = (...args) => {
    Logger.error(args.join(' '));
    originalConsole.error.apply(console, args);
  };
}

// Configure React Error Boundary logging
export const logErrorBoundary = (error, errorInfo) => {
  Logger.react.error({
    error,
    componentStack: errorInfo?.componentStack
  });
};

// Configure i18n error logging
export const logI18nError = (error) => {
  Logger.i18n.error(error);
};

// Configure OAuth error logging
export const logOAuthError = (error, context) => {
  Logger.oauth.error(error, context);
};

// Configure Stripe warning/error logging
export const logStripeWarning = (message) => {
  Logger.stripe.warning(message);
};

export const logStripeError = (error) => {
  Logger.stripe.error(error);
};

// Configure network request logging
export const logNetworkRequest = (config) => {
  Logger.network.request(config);
};

export const logNetworkResponse = (response) => {
  Logger.network.response(response);
};

export const logNetworkError = (error) => {
  Logger.network.error(error);
};

// Configure performance logging
export const logPerformanceWarning = (resource) => {
  Logger.performance.warning(resource);
};

export default {
  logErrorBoundary,
  logI18nError,
  logOAuthError,
  logStripeWarning,
  logStripeError,
  logNetworkRequest,
  logNetworkResponse,
  logNetworkError,
  logPerformanceWarning
}; 