import axios from 'axios';

// API Base URL configuration
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8787/api';

// Cloudflare Access credentials
const CF_ACCESS_CLIENT_ID = process.env.REACT_APP_CF_ACCESS_CLIENT_ID || '08e44fe3278dc70819741c5a55335ef3.access';
const CF_ACCESS_CLIENT_SECRET = process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET || '1930bf0bca8e12326e50bad5af50fddc116c58a9c639e451b7edaff049bed21c';

// Error handling function
const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error('Response Error:', {
      data: error.response.data,
      status: error.response.status,
      headers: error.response.headers
    });
    return Promise.reject(error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    console.error('Request Error:', error.request);
    return Promise.reject({
      message: 'No response received from server',
      status: undefined,
      data: undefined,
      headers: undefined,
      url: undefined
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error:', error.message);
    return Promise.reject({
      message: error.message,
      status: undefined,
      data: undefined,
      headers: undefined,
      url: undefined
    });
  }
};

// Create axios instance with modified configuration
const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 15000, // Increased timeout
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'CF-Access-Client-Id': CF_ACCESS_CLIENT_ID,
    'CF-Access-Client-Secret': CF_ACCESS_CLIENT_SECRET
  }
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    // Log the request for debugging
    console.log('API Request:', {
      url: config.url,
      method: config.method,
      data: config.data,
      headers: { ...config.headers, 'CF-Access-Client-Secret': '[REDACTED]' }
    });
    
    // Add auth token if available (check cookies first, then localStorage)
    const getCookieValue = (name) => {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    };
    
    const token = getCookieValue('jmf_token') || localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    // Ensure Cloudflare Access credentials are always included
    config.headers['CF-Access-Client-Id'] = CF_ACCESS_CLIENT_ID;
    config.headers['CF-Access-Client-Secret'] = CF_ACCESS_CLIENT_SECRET;
    
    // For OAuth endpoints, use a different approach
    if (config.url.includes('/auth/google/init') || 
        config.url.includes('/auth/github/init') ||
        config.url.includes('/auth/oauth/callback')) {
      
      // Use a more permissive CORS approach for OAuth endpoints
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
      
      // Add a timestamp to prevent caching
      const timestamp = new Date().getTime();
      config.url = config.url + (config.url.includes('?') ? '&' : '?') + '_t=' + timestamp;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Handle 401 Unauthorized errors (token expired)
    if (error.response && error.response.status === 401) {
      // Helper function to get cookie value
      const getCookieValue = (name) => {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      };
      
      // Check for refresh token in cookies first, then localStorage
      const refreshToken = getCookieValue('jmf_refresh_token') || localStorage.getItem('refreshToken');
      
      // If we have a refresh token, try to get a new access token
      if (refreshToken) {
        try {
          // Import the refreshToken function dynamically to avoid circular dependencies
          const authService = await import('./auth');
          const response = await authService.refreshToken(refreshToken);
          
          // Store the new tokens
          if (response.token) {
            // Set in cookie (more secure)
            document.cookie = `jmf_token=${response.token}; path=/; max-age=${60*60*24}; SameSite=Strict${window.location.protocol === 'https:' ? '; Secure' : ''}`;
            // Also set in localStorage as fallback
            localStorage.setItem('token', response.token);
          }
          
          if (response.refreshToken) {
            // Set in cookie (more secure)
            document.cookie = `jmf_refresh_token=${response.refreshToken}; path=/; max-age=${60*60*24*7}; SameSite=Strict${window.location.protocol === 'https:' ? '; Secure' : ''}`;
            // Also set in localStorage as fallback
            localStorage.setItem('refreshToken', response.refreshToken);
          }
          
          // Retry the original request with the new token
          const originalRequest = error.config;
          originalRequest.headers.Authorization = `Bearer ${response.token}`;
          return instance(originalRequest);
        } catch (refreshError) {
          // If refresh token is invalid, clear tokens and redirect to login
          document.cookie = 'jmf_token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
          document.cookie = 'jmf_refresh_token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location.href = '/auth/login';
          return Promise.reject(refreshError);
        }
      } else {
        // No refresh token, redirect to login
        document.cookie = 'jmf_token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        localStorage.removeItem('token');
        window.location.href = '/auth/login';
      }
    }
    
    return handleApiError(error);
  }
);

// Create a special function for OAuth requests that uses fetch directly
export const fetchWithCredentials = async (url, method = 'GET', data = null) => {
  const fullUrl = url.startsWith('http') ? url : `${API_BASE_URL}${url}`;
  
  // Prepare headers
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'CF-Access-Client-Id': CF_ACCESS_CLIENT_ID,
    'CF-Access-Client-Secret': CF_ACCESS_CLIENT_SECRET,
    'X-Requested-With': 'XMLHttpRequest'
  };
  
  // Helper function to get cookie value
  const getCookieValue = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  
  // Add auth token if available (check cookies first, then localStorage)
  const token = getCookieValue('jmf_token') || localStorage.getItem('token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  
  // For OAuth endpoints, we'll use a direct approach
  if (url.includes('/auth/google/init') || url.includes('/auth/github/init')) {
    // For OAuth initialization, construct the URL directly
    const state = data?.state || generateRandomString(32);
    const redirectUri = data?.redirect_uri || `${window.location.origin}/auth/oauth/callback`;
    
    let authUrl;
    if (url.includes('google')) {
      const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '1234567890-abcdefghijklmnopqrstuvwxyz.apps.googleusercontent.com';
      authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&response_type=code&scope=email%20profile&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}`;
    } else {
      const GITHUB_CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID || 'abcdef1234567890';
      authUrl = `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}&scope=user:email`;
    }
    
    // Store the state for verification later
    sessionStorage.setItem('oauth_state', state);
    
    return { authUrl, state };
  }
  
  // For regular API requests, use fetch with appropriate options
  try {
    console.log(`Fetch request to ${fullUrl}`, { method, headers, data });
    
    const options = {
      method,
      headers,
      credentials: 'include'
    };
    
    // Add body for non-GET requests
    if (data && method !== 'GET') {
      options.body = JSON.stringify(data);
    }
    
    const response = await fetch(fullUrl, options);
    
    // Check if the response is ok
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw {
        response: {
          status: response.status,
          data: errorData,
          headers: Object.fromEntries([...response.headers.entries()])
        }
      };
    }
    
    // Parse the response
    return await response.json();
  } catch (error) {
    return handleApiError(error);
  }
};

// Helper function to generate random strings
function generateRandomString(length) {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export default instance;
