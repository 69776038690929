import api, { fetchWithCredentials } from './api';

export const login = async (credentials) => {
  console.log('🔐 Attempting login with:', { email: credentials.email });
  try {
    const response = await api.post('/auth/login', credentials);
    console.log('✅ Login response:', response.data);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      if (response.data.refreshToken) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
      }
    }
    return response.data;
  } catch (error) {
    console.error('❌ Login error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    throw error.response?.data || error;
  }
};

export const register = async (userData) => {
  try {
    const response = await api.post('/auth/register', {
      username: userData.username,
      email: userData.email,
      password: userData.password,
      first_name: userData.firstName,
      last_name: userData.lastName
    });
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error.response?.data || error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await api.post('/auth/verify-email', { token });
    return response.data;
  } catch (error) {
    console.error('Email verification error:', error);
    throw error.response?.data || error;
  }
};

export const resendVerification = async (email) => {
  try {
    const response = await api.post('/auth/resend-verification', { email });
    return response.data;
  } catch (error) {
    console.error('Resend verification error:', error);
    throw error.response?.data || error;
  }
};

export const logout = async () => {
  try {
    await api.post('/auth/logout');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return { success: true };
  } catch (error) {
    console.error('Logout error:', error);
    // Still remove tokens even if the API call fails
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    throw error.response?.data || error;
  }
};

export const getProfile = async () => {
  try {
    const response = await api.get('/auth/profile');
    return response.data;
  } catch (error) {
    console.error('Get profile error:', error);
    throw error.response?.data || error;
  }
};

export const updateProfile = async (userData) => {
  try {
    const response = await api.put('/auth/profile', userData);
    return response.data;
  } catch (error) {
    console.error('Update profile error:', error);
    throw error.response?.data || error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await api.post('/auth/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error('Forgot password error:', error);
    throw error.response?.data || error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await api.post('/auth/reset-password', {
      token,
      password: newPassword
    });
    return response.data;
  } catch (error) {
    console.error('Reset password error:', error);
    throw error.response?.data || error;
  }
};

// OAuth methods
export const initiateGoogleAuth = async () => {
  try {
    const state = generateRandomString(32);
    
    console.log('🔐 Initiating Google OAuth:', {
      redirect_uri: `${window.location.origin}/auth/oauth/callback`,
      state
    });

    // Use the direct approach for OAuth
    const result = await fetchWithCredentials(
      '/auth/google/init',
      'POST',
      {
        redirect_uri: `${window.location.origin}/auth/oauth/callback`,
        state
      }
    );
    
    console.log('✅ Google OAuth response:', result);
    
    // Redirect to the authorization URL
    if (result?.authUrl) {
      window.location.href = result.authUrl;
    } else {
      throw new Error('No authentication URL received');
    }
  } catch (error) {
    console.error('❌ Google OAuth initialization error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message || error,
      headers: error.response?.headers,
      url: error.config?.url
    });
    throw error.response?.data || error;
  }
};

export const initiateGithubAuth = async () => {
  try {
    const state = generateRandomString(32);
    
    console.log('🔐 Initiating GitHub OAuth:', {
      redirect_uri: `${window.location.origin}/auth/oauth/callback`,
      state
    });

    // Use the direct approach for OAuth
    const result = await fetchWithCredentials(
      '/auth/github/init',
      'POST',
      {
        redirect_uri: `${window.location.origin}/auth/oauth/callback`,
        state
      }
    );
    
    console.log('✅ GitHub OAuth response:', result);
    
    // Redirect to the authorization URL
    if (result?.authUrl) {
      window.location.href = result.authUrl;
    } else {
      throw new Error('No authentication URL received');
    }
  } catch (error) {
    console.error('❌ GitHub OAuth initialization error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message || error,
      headers: error.response?.headers,
      url: error.config?.url
    });
    throw error.response?.data || error;
  }
};

export const handleOAuthCallback = async (provider, code, state) => {
  try {
    // Verify state matches what we stored
    const storedState = sessionStorage.getItem('oauth_state');
    if (state !== storedState) {
      throw new Error('OAuth state mismatch - possible CSRF attack');
    }
    
    // Clear the stored state
    sessionStorage.removeItem('oauth_state');
    
    console.log(`🔐 Processing ${provider} OAuth callback:`, { code, state });

    // Send the code and state to the backend
    const response = await api.post(`/auth/${provider}/callback`, {
      code,
      state,
      redirect_uri: `${window.location.origin}/auth/oauth/callback`
    });

    if (!response.data.success) {
      throw new Error(response.data.message || 'OAuth authentication failed');
    }

    return response.data;
  } catch (error) {
    console.error(`❌ ${provider} OAuth callback error:`, {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message || error,
      headers: error.response?.headers,
      url: error.config?.url
    });
    throw error.response?.data || error;
  }
};

function generateRandomString(length) {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export const refreshToken = async (refreshToken) => {
  try {
    // Use the direct fetch method instead of axios
    const response = await fetchWithCredentials(
      '/auth/refresh',
      'POST',
      { refreshToken }
    );
    
    if (response.token) {
      localStorage.setItem('token', response.token);
      if (response.refreshToken) {
        localStorage.setItem('refreshToken', response.refreshToken);
      }
      return response;
    } else {
      throw new Error('No token received');
    }
  } catch (error) {
    console.error('❌ Token refresh error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message || error,
      headers: error.response?.headers,
      url: error.config?.url
    });
    throw error.response?.data || error;
  }
};

const authService = {
  login,
  register,
  verifyEmail,
  resendVerification,
  logout,
  getProfile,
  updateProfile,
  forgotPassword,
  resetPassword,
  initiateGoogleAuth,
  initiateGithubAuth,
  handleOAuthCallback,
  refreshToken
};

export default authService;
