/**
 * Sets a cookie with the given name, value, and expiration days
 * @param {string} name - The name of the cookie
 * @param {string} value - The value to store in the cookie
 * @param {number} days - Number of days until the cookie expires
 * @param {boolean} secure - Whether the cookie should be secure (HTTPS only)
 */
export const setCookie = (name, value, days = 7, secure = true) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  const secureFlag = secure && window.location.protocol === 'https:' ? '; secure' : '';
  const sameSite = secure ? '; samesite=strict' : '';
  document.cookie = `${name}=${value}; ${expires}; path=/${secureFlag}${sameSite}`;
};

/**
 * Gets a cookie value by name
 * @param {string} name - The name of the cookie to retrieve
 * @returns {string|null} The cookie value or null if not found
 */
export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/**
 * Removes a cookie by name
 * @param {string} name - The name of the cookie to remove
 */
export const removeCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const cookieManager = {
  setConsent: (type, value) => {
    localStorage.setItem(`cookieConsent_${type}`, value);
  },

  getConsent: (type) => {
    return localStorage.getItem(`cookieConsent_${type}`) === 'true';
  },

  hasGivenConsent: () => {
    return localStorage.getItem('cookieConsent') !== null;
  },

  getAllConsents: () => {
    return {
      essential: true, // Always true as these are required
      functional: localStorage.getItem('cookieConsent_functional') === 'true',
      analytics: localStorage.getItem('cookieConsent_analytics') === 'true',
      marketing: localStorage.getItem('cookieConsent_marketing') === 'true'
    };
  }
}; 