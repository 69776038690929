import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

// At the top of the file, before the RequestQueue class
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

class RequestQueue {
  constructor() {
    this.queue = [];
    this.processing = false;
    this.retryDelays = [1000, 2000, 5000, 10000]; // Retry delays in milliseconds
    this.maxRetries = 3;
  }

  async add(request) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        request,
        resolve,
        reject,
        retryCount: 0
      });
      this.processQueue();
    });
  }

  async processQueue() {
    if (this.processing || this.queue.length === 0) return;

    this.processing = true;
    const { request, resolve, reject, retryCount } = this.queue[0];

    try {
      const response = await this.executeRequest(request);
      resolve(response);
      this.queue.shift();
    } catch (error) {
      if (retryCount < this.maxRetries && this.shouldRetry(error)) {
        this.handleRetry();
      } else {
        reject(error);
        this.queue.shift();
      }
    } finally {
      this.processing = false;
      if (this.queue.length > 0) {
        setTimeout(() => this.processQueue(), 100); // Small delay between requests
      }
    }
  }

  async executeRequest(request) {
    const { method, url, data, headers } = request;
    return await api[method.toLowerCase()](url, data, { headers });
  }

  shouldRetry(error) {
    const status = error.response?.status;
    return (
      status === 429 || // Rate limit exceeded
      status === 503 || // Service unavailable
      status === 504 || // Gateway timeout
      !status // Network error
    );
  }

  handleRetry() {
    const item = this.queue[0];
    item.retryCount++;
    const delay = this.retryDelays[Math.min(item.retryCount - 1, this.retryDelays.length - 1)];
    setTimeout(() => this.processQueue(), delay);
  }
}

export const requestQueue = new RequestQueue();
export default requestQueue; 