import React from 'react';
import { Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

const StatCard = ({ stat, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const { number } = useSpring({
    from: { number: 0 },
    number: inView ? parseFloat(stat.value) || 0 : 0,
    delay: index * 100,
    config: { mass: 1, tension: 20, friction: 10 }
  });

  return (
    <Grid item xs={12} sm={6} md={3}>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        whileHover={{ scale: 1.05 }}
        className="stat-card"
      >
        <Typography variant="h3" color="primary" gutterBottom>
          <animated.span>
            {typeof stat.value === 'number' 
              ? number.to(n => n.toFixed(1))
              : stat.value}
          </animated.span>
          {stat.suffix}
        </Typography>
        <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          {stat.label}
        </Typography>
      </motion.div>
    </Grid>
  );
};

export default StatCard; 