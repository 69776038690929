import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/AuthContext';
import { AppProvider } from './context/AppContext';
import { CartProvider } from './context/CartContext';
import theme from './theme';
import AppRoutes from './Routes';
import LoadingScreen from './components/LoadingScreen';
import { Box } from '@mui/material';
import './styles/index.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <CartProvider>
            <AppProvider>
              <Box 
                className="wrapper"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                  width: '100%',
                  bgcolor: 'background.default',
                  color: 'text.primary',
                  position: 'relative',
                  m: 0,
                  p: 0,
                  '&::before': {
                    content: '""',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at top left, rgba(33, 150, 243, 0.1), transparent 40%), radial-gradient(circle at top right, rgba(255, 64, 129, 0.1), transparent 40%)',
                    pointerEvents: 'none',
                    zIndex: 0
                  }
                }}
              >
                <React.Suspense fallback={<LoadingScreen />}>
                  <AppRoutes />
                </React.Suspense>
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                  style={{
                    zIndex: 9999,
                    bottom: '50%',
                    transform: 'translateY(50%)',
                    left: '50%',
                    marginLeft: '-175px' // Half of the notification width
                  }}
                  toastStyle={{
                    background: 'rgba(30, 30, 30, 0.9)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '8px',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                    color: '#fff',
                    width: '350px'
                  }}
                />
              </Box>
            </AppProvider>
          </CartProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;