import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Avatar,
  Button,
  TextField,
  IconButton,
  Alert,
  CircularProgress,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  Divider,
  Chip,
  Tooltip,
  Badge,
  Fade,
  Zoom,
  Grow
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  PhotoCamera as PhotoCameraIcon,
  GitHub as GitHubIcon,
  Google as GoogleIcon,
  Security as SecurityIcon,
  Notifications as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
  Badge as BadgeIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  CalendarMonth as CalendarMonthIcon,
  VpnKey as VpnKeyIcon,
  Verified as VerifiedIcon,
  CreditCard as CreditCardIcon,
  Storage as StorageIcon,
  Link as LinkIcon,
  ContentCopy as ContentCopyIcon,
  Visibility as Visibility,
  VisibilityOff as VisibilityOff
} from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { userService } from '../../services/api';
import '../../styles/profile.css';

const Profile = () => {
  const { user, updateUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    bio: '',
    location: '',
    website: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    notifications: {
      email: true,
      push: true,
      marketing: false
    },
    connectedAccounts: {
      google: false,
      github: false
    }
  });

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      setLoading(true);
      const response = await userService.getProfile();
      
      if (response.data && response.data.data) {
        const userData = response.data.data;
        setProfileData({
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          email: userData.email || '',
          username: userData.username || '',
          avatar: userData.avatarUrl || null,
          memberSince: userData.memberSince ? new Date(userData.memberSince).toLocaleDateString() : 'N/A',
          role: userData.role || 'user',
          credits: userData.credits || 0,
          serverLimit: userData.serverLimit || 0,
          referralCode: userData.referralCode || '',
          notifications: userData.notifications || {
            email: true,
            push: true,
            serverStatus: true,
            marketing: false
          },
          connectedAccounts: {
            google: userData.connectedAccounts?.google || false,
            github: userData.connectedAccounts?.github || false
          }
        });
      } else {
        setError('Failed to load profile data: Invalid response format');
      }
    } catch (err) {
      console.error('Error fetching profile data:', err);
      setError('Failed to load profile data: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNotificationChange = (setting) => (e) => {
    setProfileData(prev => ({
      ...prev,
      notifications: {
        ...prev.notifications,
        [setting]: e.target.checked
      }
    }));
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append('avatar', file);
        
        const response = await userService.uploadAvatar(formData);
        
        if (response.data && response.data.success) {
          // Update the avatar in the profile data
          setProfileData(prev => ({
            ...prev,
            avatar: response.data.data.avatarUrl
          }));
          
          // Refresh the profile data to get the updated avatar URL
          fetchProfileData();
          
          setSuccess('Avatar updated successfully');
        } else {
          setError(response.data?.message || 'Failed to update avatar');
        }
      } catch (err) {
        console.error('Error uploading avatar:', err);
        setError(err.response?.data?.message || 'Failed to update avatar');
      }
    }
  };

  const handleSaveProfile = async () => {
    try {
      setSaving(true);
      
      // Prepare the data to send to the API
      const dataToUpdate = {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        username: profileData.username
      };
      
      const response = await userService.updateProfile(dataToUpdate);
      
      if (response.data && response.data.success) {
        // Update the user context if needed
        if (updateUser) {
          updateUser({
            firstName: profileData.firstName,
            lastName: profileData.lastName,
            username: profileData.username
          });
        }
        
        setSuccess('Profile updated successfully');
        setEditMode(false);
      } else {
        setError(response.data?.message || 'Failed to update profile');
      }
    } catch (err) {
      console.error('Error updating profile:', err);
      setError(err.response?.data?.message || 'Failed to update profile');
    } finally {
      setSaving(false);
    }
  };

  const handleUpdatePassword = async () => {
    // Validate passwords
    if (profileData.newPassword !== profileData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    if (profileData.newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    try {
      setSaving(true);
      const response = await userService.updatePassword({
        currentPassword: profileData.currentPassword,
        newPassword: profileData.newPassword
      });

      if (response.data && response.data.success) {
        setSuccess('Password updated successfully');
        setProfileData(prev => ({
          ...prev,
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }));
      } else {
        setError(response.data?.message || 'Failed to update password');
      }
    } catch (err) {
      console.error('Error updating password:', err);
      setError(err.response?.data?.message || 'Failed to update password');
    } finally {
      setSaving(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const copyReferralCode = () => {
    navigator.clipboard.writeText(profileData.referralCode);
    setSuccess('Referral code copied to clipboard');
  };

  /**
   * Handle connecting or disconnecting an account
   * @param {string} provider - The provider to connect/disconnect (e.g., 'google', 'github')
   */
  const handleConnectAccount = (provider) => {
    // Check if already connected
    const isConnected = profileData.connectedAccounts?.[provider];
    
    if (isConnected) {
      // Handle disconnecting the account
      setProfileData(prev => ({
        ...prev,
        connectedAccounts: {
          ...prev.connectedAccounts,
          [provider]: false
        }
      }));
      setSuccess(`Disconnected ${provider} account successfully`);
    } else {
      // Handle connecting the account
      // In a real implementation, this would redirect to OAuth flow
      // For now, we'll just simulate a successful connection
      setProfileData(prev => ({
        ...prev,
        connectedAccounts: {
          ...prev.connectedAccounts,
          [provider]: true
        }
      }));
      setSuccess(`Connected ${provider} account successfully`);
    }
  };

  const handleNotificationSettingsUpdate = () => {
    // Implementation for updating notification settings
    setSuccess('Notification settings updated successfully');
  };

  if (loading) {
    return (
      <Box sx={{ 
        flexGrow: 1, 
        width: '100%', 
        pt: 8,
        pb: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.9)'
      }}>
        <CircularProgress size={60} sx={{ color: '#2196F3' }} />
        <Typography variant="h6" className="profile-loading-text">
          Loading your profile...
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          My Profile
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {success && (
          <Alert severity="success" sx={{ mb: 3 }}>
            {success}
          </Alert>
        )}
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            {/* Profile Header */}
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={2} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <IconButton 
                          size="small" 
                          sx={{ 
                            bgcolor: 'primary.main',
                            '&:hover': { bgcolor: 'primary.dark' }
                          }}
                          component="label"
                        >
                          <PhotoCameraIcon fontSize="small" />
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleAvatarChange}
                          />
                        </IconButton>
                      }
                    >
                      <Avatar 
                        src={profileData.avatar} 
                        alt={profileData.username}
                        sx={{ width: 100, height: 100 }}
                      />
                    </Badge>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h5">
                      {profileData.firstName} {profileData.lastName}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                      @{profileData.username}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                      <Chip 
                        icon={<EmailIcon />} 
                        label={profileData.email} 
                        variant="outlined" 
                        size="small"
                      />
                      {profileData.isVerified && (
                        <Chip 
                          icon={<VerifiedIcon />} 
                          label="Verified" 
                          color="success" 
                          size="small"
                        />
                      )}
                      <Chip 
                        icon={<CalendarMonthIcon />} 
                        label={`Member since ${new Date(profileData.memberSince).toLocaleDateString()}`} 
                        variant="outlined" 
                        size="small"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2} sx={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      startIcon={editMode ? <SaveIcon /> : <EditIcon />}
                      onClick={editMode ? handleSaveProfile : () => setEditMode(true)}
                      disabled={saving}
                    >
                      {editMode ? 'Save' : 'Edit Profile'}
                    </Button>
                    {editMode && (
                      <Button
                        variant="outlined"
                        startIcon={<CancelIcon />}
                        onClick={() => {
                          setEditMode(false);
                          fetchProfileData(); // Reset to original data
                        }}
                        sx={{ mt: 1 }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            {/* Tabs */}
            <Grid item xs={12}>
              <Paper sx={{ p: 0 }}>
                <Tabs
                  value={tabValue}
                  onChange={(e, newValue) => setTabValue(newValue)}
                  variant="fullWidth"
                >
                  <Tab icon={<AccountCircleIcon />} label="Account" />
                  <Tab icon={<SecurityIcon />} label="Security" />
                  <Tab icon={<NotificationsIcon />} label="Notifications" />
                  <Tab icon={<LinkIcon />} label="Connected Accounts" />
                </Tabs>
              </Paper>
            </Grid>
            
            {/* Tab Content */}
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                {/* Account Tab */}
                {tabValue === 0 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="First Name"
                        name="firstName"
                        value={profileData.firstName}
                        onChange={handleInputChange}
                        disabled={!editMode}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        value={profileData.lastName}
                        onChange={handleInputChange}
                        disabled={!editMode}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Username"
                        name="username"
                        value={profileData.username}
                        onChange={handleInputChange}
                        disabled={!editMode}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={profileData.email}
                        onChange={handleInputChange}
                        disabled={true} // Email should not be editable here
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Bio"
                        name="bio"
                        value={profileData.bio || ''}
                        onChange={handleInputChange}
                        disabled={!editMode}
                        margin="normal"
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Location"
                        name="location"
                        value={profileData.location || ''}
                        onChange={handleInputChange}
                        disabled={!editMode}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Website"
                        name="website"
                        value={profileData.website || ''}
                        onChange={handleInputChange}
                        disabled={!editMode}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                )}
                
                {/* Security Tab */}
                {tabValue === 1 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Change Password
                      </Typography>
                      <Divider sx={{ mb: 3 }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Current Password"
                        name="currentPassword"
                        type={showPassword ? 'text' : 'password'}
                        value={profileData.currentPassword}
                        onChange={handleInputChange}
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="New Password"
                        name="newPassword"
                        type={showPassword ? 'text' : 'password'}
                        value={profileData.newPassword}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Confirm New Password"
                        name="confirmPassword"
                        type={showPassword ? 'text' : 'password'}
                        value={profileData.confirmPassword}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={handleUpdatePassword}
                        disabled={saving}
                      >
                        Update Password
                      </Button>
                    </Grid>
                    
                    <Grid item xs={12} sx={{ mt: 4 }}>
                      <Typography variant="h6" gutterBottom>
                        Account Security
                      </Typography>
                      <Divider sx={{ mb: 3 }} />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={profileData.twoFactorEnabled || false}
                            onChange={(e) => setProfileData(prev => ({
                              ...prev,
                              twoFactorEnabled: e.target.checked
                            }))}
                            color="primary"
                          />
                        }
                        label="Enable Two-Factor Authentication"
                      />
                    </Grid>
                  </Grid>
                )}
                
                {/* Notifications Tab */}
                {tabValue === 2 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Notification Preferences
                      </Typography>
                      <Divider sx={{ mb: 3 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={profileData.notifications?.email || false}
                            onChange={(e) => setProfileData(prev => ({
                              ...prev,
                              notifications: {
                                ...prev.notifications,
                                email: e.target.checked
                              }
                            }))}
                            color="primary"
                          />
                        }
                        label="Email Notifications"
                      />
                      <Typography variant="body2" color="textSecondary" sx={{ ml: 4, mb: 2 }}>
                        Receive notifications about your account, servers, and important updates via email.
                      </Typography>
                      
                      <FormControlLabel
                        control={
                          <Switch
                            checked={profileData.notifications?.push || false}
                            onChange={(e) => setProfileData(prev => ({
                              ...prev,
                              notifications: {
                                ...prev.notifications,
                                push: e.target.checked
                              }
                            }))}
                            color="primary"
                          />
                        }
                        label="Push Notifications"
                      />
                      <Typography variant="body2" color="textSecondary" sx={{ ml: 4, mb: 2 }}>
                        Receive push notifications in your browser about server status and alerts.
                      </Typography>
                      
                      <FormControlLabel
                        control={
                          <Switch
                            checked={profileData.notifications?.marketing || false}
                            onChange={(e) => setProfileData(prev => ({
                              ...prev,
                              notifications: {
                                ...prev.notifications,
                                marketing: e.target.checked
                              }
                            }))}
                            color="primary"
                          />
                        }
                        label="Marketing Communications"
                      />
                      <Typography variant="body2" color="textSecondary" sx={{ ml: 4, mb: 2 }}>
                        Receive updates about new features, promotions, and special offers.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={handleNotificationSettingsUpdate}
                        disabled={saving}
                      >
                        Save Notification Settings
                      </Button>
                    </Grid>
                  </Grid>
                )}
                
                {/* Connected Accounts Tab */}
                {tabValue === 3 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Connected Accounts
                      </Typography>
                      <Divider sx={{ mb: 3 }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <GoogleIcon sx={{ mr: 2, color: '#DB4437' }} />
                          <Box>
                            <Typography variant="subtitle1">Google</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {profileData.connectedAccounts?.google ? 
                                'Connected' : 'Not connected'}
                            </Typography>
                          </Box>
                        </Box>
                        <Button
                          variant={profileData.connectedAccounts?.google ? "outlined" : "contained"}
                          color={profileData.connectedAccounts?.google ? "error" : "primary"}
                          onClick={() => handleConnectAccount('google')}
                        >
                          {profileData.connectedAccounts?.google ? 'Disconnect' : 'Connect'}
                        </Button>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <GitHubIcon sx={{ mr: 2 }} />
                          <Box>
                            <Typography variant="subtitle1">GitHub</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {profileData.connectedAccounts?.github ? 
                                'Connected' : 'Not connected'}
                            </Typography>
                          </Box>
                        </Box>
                        <Button
                          variant={profileData.connectedAccounts?.github ? "outlined" : "contained"}
                          color={profileData.connectedAccounts?.github ? "error" : "primary"}
                          onClick={() => handleConnectAccount('github')}
                        >
                          {profileData.connectedAccounts?.github ? 'Disconnect' : 'Connect'}
                        </Button>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default Profile; 