import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { authService } from '../../services/api';

const CodeInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontSize: '2rem',
    letterSpacing: '0.5rem',
    textAlign: 'center',
    '& input': {
      textAlign: 'center',
      letterSpacing: '0.5rem',
      padding: '16px',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '8px',
      color: theme.palette.primary.main
    }
  }
}));

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Get token from URL if present
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const code = location.state?.code;
    
    // Set verification code from URL or state
    if (token) {
      const formattedToken = token.slice(0, 6).toUpperCase();
      setVerificationCode(formattedToken);
      // Auto-verify if token is in URL
      if (formattedToken.length === 6) {
        autoVerifyEmail(formattedToken);
      }
    } else if (code) {
      setVerificationCode(code.toUpperCase());
      // Auto-verify if code is in state
      autoVerifyEmail(code);
    }
    
    // Get email from location state if available
    if (location.state?.email) {
      setEmail(location.state.email);
    }
    
    // Display message from location state if available
    if (location.state?.message) {
      setSuccess(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate]);
  
  // Function to automatically verify email
  const autoVerifyEmail = async (code) => {
    if (!code || code.length !== 6) return;
    
    setLoading(true);
    setError('');
    
    try {
      const response = await authService.verifyEmail(code);
      setSuccess(true);
      setTimeout(() => {
        navigate('/auth/login', { 
          state: { message: 'Your email has been verified successfully! You can now log in to your account.' }
        });
      }, 3000);
    } catch (err) {
      setError(err.message || 'Failed to verify email');
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await authService.verifyEmail(verificationCode);
      setSuccess(true);
      setTimeout(() => {
        navigate('/auth/login', { 
          state: { message: 'Your email has been verified successfully! You can now log in to your account.' }
        });
      }, 3000);
    } catch (err) {
      setError(err.message || 'Failed to verify email');
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    setLoading(true);
    try {
      await authService.resendVerification(email);
      setSuccess(true);
      setError('');
    } catch (err) {
      setError(err.message || 'Failed to resend verification email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000000',
        pt: '64px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at top left, rgba(33, 150, 243, 0.1), transparent 40%), radial-gradient(circle at top right, rgba(255, 64, 129, 0.1), transparent 40%)',
          pointerEvents: 'none',
        }
      }}
    >
      <Container maxWidth="sm">
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4, 
            background: 'rgba(45, 45, 45, 0.9)',
            backdropFilter: 'blur(10px)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.1)'
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '2rem', sm: '2.5rem' },
                fontWeight: 700,
                background: 'linear-gradient(45deg, #2196F3, #FF4081)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                mb: 2
              }}
            >
              {loading && location.state?.code ? 'Verifying Email...' : 'Verify Your Email'}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
                maxWidth: '400px',
                margin: '0 auto'
              }}
            >
              {loading && location.state?.code 
                ? 'Please wait while we verify your email address automatically...'
                : `Please enter the verification code sent to ${email ? email : 'your email address'}`
              }
            </Typography>
            
            {!loading && !location.state?.code && (
              <Typography
                variant="body2"
                sx={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  maxWidth: '400px',
                  margin: '10px auto 0',
                  fontStyle: 'italic'
                }}
              >
                You can also click the verification link in your email to verify automatically.
              </Typography>
            )}
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {location.state?.message || 'Email verified successfully! Redirecting to login...'}
            </Alert>
          )}

          {/* Show loading indicator if auto-verifying */}
          {loading && location.state?.code && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress size={60} sx={{ color: '#2196F3' }} />
            </Box>
          )}

          {/* Only show the form if not auto-verifying */}
          {(!loading || !location.state?.code) && (
            <>
              <form onSubmit={handleVerification}>
                <Box sx={{ mb: 4 }}>
                  <CodeInput
                    fullWidth
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value.toUpperCase())}
                    placeholder="000000"
                    inputProps={{
                      maxLength: 6,
                      style: { textTransform: 'uppercase' }
                    }}
                  />
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading || verificationCode.length !== 6}
                  sx={{
                    py: 2,
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    background: 'linear-gradient(45deg, #2196F3, #FF4081)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #1976D2, #D81B60)',
                    }
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Verify Email'}
                </Button>
              </form>

              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ color: 'rgba(255, 255, 255, 0.5)', mb: 2 }}
                >
                  Didn't receive the code?
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  label="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    }
                  }}
                />
                <Button
                  onClick={handleResendVerification}
                  disabled={loading || !email}
                  sx={{
                    color: '#2196F3',
                    '&:hover': {
                      backgroundColor: 'rgba(33, 150, 243, 0.1)',
                    }
                  }}
                >
                  Resend Verification Email
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default VerifyEmail;
