import React from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import {
  BoltOutlined as InstantIcon,
  MemoryOutlined as InfrastructureIcon,
  SecurityOutlined as SecurityIcon,
  SupportOutlined as SupportIcon,
  CloudOutlined as UptimeIcon,
  SpeedOutlined as PerformanceIcon,
  BackupOutlined as BackupIcon,
  UpdateOutlined as UpdateIcon
} from '@mui/icons-material';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <Grid item xs={12} md={6} lg={3}>
    <Paper
      elevation={0}
      sx={{
        p: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
        }
      }}
    >
      <Box
        sx={{
          width: 80,
          height: 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: 'rgba(52, 211, 153, 0.1)',
          mb: 2
        }}
      >
        <Icon
          sx={{
            fontSize: 40,
            color: '#34D399'
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          color: 'white',
          fontWeight: 'bold'
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'rgba(255, 255, 255, 0.7)',
          lineHeight: 1.6
        }}
      >
        {description}
      </Typography>
    </Paper>
  </Grid>
);

const Features = () => {
  const features = [
    {
      icon: InstantIcon,
      title: "Instant Server Access",
      description: "Get your servers up and running in no time, with instant access to high-performance resources tailored for gaming and business applications."
    },
    {
      icon: InfrastructureIcon,
      title: "High-Performance Infrastructure",
      description: "Experience seamless operations with our top-of-the-line hardware, engineered for maximum reliability and efficiency in demanding environments."
    },
    {
      icon: SecurityIcon,
      title: "Advanced Security Measures",
      description: "Protect your servers from cyber threats with our robust, multi-layered defense system, ensuring uninterrupted service and data integrity."
    },
    {
      icon: SupportIcon,
      title: "Timely Technical Assistance",
      description: "Enjoy prompt and dedicated support from our team of experts, ensuring any issues are resolved quickly and your operations remain uninterrupted."
    },
    {
      icon: UptimeIcon,
      title: "99.9% Uptime Guarantee",
      description: "Rest assured with our commitment to maximum server availability, backed by enterprise-grade infrastructure and redundant systems."
    },
    {
      icon: PerformanceIcon,
      title: "Optimized Performance",
      description: "Experience lightning-fast speeds with our optimized server configurations, designed specifically for gaming and high-demand applications."
    },
    {
      icon: BackupIcon,
      title: "Automated Backups",
      description: "Keep your data safe with automated backup systems, ensuring your valuable information is always protected and easily recoverable."
    },
    {
      icon: UpdateIcon,
      title: "Regular Updates",
      description: "Stay current with automatic system updates and security patches, keeping your server environment secure and performing at its best."
    }
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: '#000000',
        py: 8
      }}
    >
      <Container maxWidth={false} sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography
            variant="h2"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              mb: 2
            }}
          >
            Our Features
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            Experience premium game server hosting with cutting-edge features designed for performance and reliability
          </Typography>
        </Box>

        {/* Features Grid */}
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features; 