import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as authService from '../api/auth';
import { Box, CircularProgress } from '@mui/material';
import { setCookie, getCookie, removeCookie } from '../utils/cookieManager';

const AuthContext = createContext(null);

const TOKEN_KEY = 'jmf_token';
const USER_KEY = 'jmf_user';
const REFRESH_TOKEN_KEY = 'jmf_refresh_token';
const REMEMBER_ME_KEY = 'jmf_remember_me';

// Cookie expiration times
const COOKIE_EXPIRES_REMEMBER = 30; // 30 days
const COOKIE_EXPIRES_DEFAULT = 1; // 1 day

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    try {
      await authService.logout();
      setUser(null);
      removeCookie(TOKEN_KEY);
      removeCookie(REFRESH_TOKEN_KEY);
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(REMEMBER_ME_KEY);
      navigate('/auth/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, [navigate]);

  const checkAuth = useCallback(async () => {
    try {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        setLoading(false);
        return;
      }
      const userData = await authService.getProfile();
      setUser(userData);
    } catch (error) {
      console.error('Auth check error:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, []);

  // Check for existing auth on mount
  useEffect(() => {
    const initAuth = async () => {
      try {
        // Check for token in cookies first (more secure), then localStorage as fallback
        let token = getCookie(TOKEN_KEY) || localStorage.getItem(TOKEN_KEY);
        
        if (token) {
          // Try to get user data from localStorage to avoid an extra API call
          const storedUser = localStorage.getItem(USER_KEY);
          
          if (storedUser) {
            setUser(JSON.parse(storedUser));
          } else {
            // If no stored user data, fetch profile from API
            const response = await authService.getProfile();
            if (response.success && response.user) {
              setUser(response.user);
              localStorage.setItem(USER_KEY, JSON.stringify(response.user));
            } else {
              // If profile fetch fails, clear auth data
              handleLogout();
            }
          }
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        handleLogout();
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  // Check token expiration periodically
  useEffect(() => {
    const interval = setInterval(() => {
      checkAuth();
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(interval);
  }, [checkAuth]);

  // Set up token refresh interval
  useEffect(() => {
    let refreshInterval;

    if (user) {
      refreshInterval = setInterval(async () => {
        try {
          const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
          if (refreshToken) {
            const response = await authService.refreshToken(refreshToken);
            if (response.token) {
              localStorage.setItem(TOKEN_KEY, response.token);
              if (response.refreshToken) {
                localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);
              }
            }
          }
        } catch (error) {
          console.error('Token refresh failed:', error);
          handleLogout();
        }
      }, 15 * 60 * 1000); // Refresh every 15 minutes
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [user, handleLogout]);

  const handleLogin = async (credentials) => {
    try {
      setError(null);
      const response = await authService.login(credentials);
      
      // Get remember me preference
      const rememberMe = credentials.rememberMe || false;
      
      // Store in cookies (more secure)
      const cookieExpires = rememberMe ? COOKIE_EXPIRES_REMEMBER : COOKIE_EXPIRES_DEFAULT;
      setCookie(TOKEN_KEY, response.token, cookieExpires);
      
      if (response.refreshToken) {
        setCookie(REFRESH_TOKEN_KEY, response.refreshToken, cookieExpires);
      }
      
      // Also store in localStorage as fallback
      localStorage.setItem(TOKEN_KEY, response.token);
      if (response.refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);
      }
      
      // Store user data
      localStorage.setItem(USER_KEY, JSON.stringify(response.user));
      
      // Store remember me preference
      localStorage.setItem(REMEMBER_ME_KEY, rememberMe);
      
      setUser(response.user);
      return { success: true, user: response.user };
    } catch (error) {
      setError(error.message || 'Login failed');
      throw error;
    }
  };

  const handleRegister = async (userData) => {
    try {
      setError(null);
      const response = await authService.register({
        username: userData.username,
        email: userData.email,
        password: userData.password,
        firstName: userData.firstName || null,
        lastName: userData.lastName || null
      });
      
      // After registration, redirect to the URL provided by the API
      if (response.redirectUrl) {
        navigate(response.redirectUrl);
      } else {
        // Fallback to the default route
        navigate('/auth/verify-email', { 
          state: { email: userData.email }
        });
      }
      
      return { success: true, message: response.message };
    } catch (error) {
      console.error('Registration error in AuthContext:', error);
      setError(error.message || 'Registration failed');
      throw error;
    }
  };

  const handleVerifyEmail = async (token) => {
    try {
      setError(null);
      const response = await authService.verifyEmail(token);
      return { success: true, message: response.message };
    } catch (error) {
      setError(error.message || 'Email verification failed');
      throw error;
    }
  };

  const handleOAuthLogin = async (provider) => {
    try {
      setError(null);
      if (provider === 'google') {
        await authService.initiateGoogleAuth();
      } else if (provider === 'github') {
        await authService.initiateGithubAuth();
      } else {
        throw new Error('Unsupported OAuth provider');
      }
    } catch (error) {
      setError(error.message || `Failed to initialize ${provider} authentication`);
      throw error;
    }
  };

  const handleOAuthCallback = async (provider, code, state, redirectUri) => {
    try {
      setError(null);
      const response = await authService.handleOAuthCallback(provider, code, state, redirectUri);
      
      // Store tokens and user data
      localStorage.setItem(TOKEN_KEY, response.token);
      if (response.refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);
      }
      localStorage.setItem(USER_KEY, JSON.stringify(response.user));
      
      setUser(response.user);
      return { success: true, user: response.user };
    } catch (error) {
      setError(error.message || 'OAuth authentication failed');
      throw error;
    }
  };

  const value = {
    user,
    loading,
    error,
    login: handleLogin,
    register: handleRegister,
    logout: handleLogout,
    verifyEmail: handleVerifyEmail,
    handleOAuthLogin,
    handleOAuthCallback,
    isAuthenticated: !!user,
    token: localStorage.getItem(TOKEN_KEY)
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 