import React from 'react';
import { Box, Container, Grid, Typography, Link, Stack } from '@mui/material';
import { YouTube, Twitter, GitHub } from '@mui/icons-material';

const Footer = () => {
  const linkStyles = {
    color: 'rgba(255,255,255,0.7)',
    textDecoration: 'none',
    width: 'fit-content',
    margin: '0 auto',
    '&:hover': {
      color: '#4A90E2',
    }
  };

  return (
    <Box
      component="footer"
      sx={{
        background: '#000000',
        py: 4,
        borderTop: '1px solid rgba(255,255,255,0.1)',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="lg" sx={{ px: { xs: 3, md: 8 } }}>
        <Grid container spacing={4} justifyContent="center">
          {/* Company Info */}
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
            <Link 
              href="/"
              sx={{ 
                ...linkStyles,
                color: '#4A90E2',
                mb: 2,
                display: 'inline-block',
                '&:hover': { color: '#67B8E3' }
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                JMF Hosting
              </Typography>
            </Link>
            <Stack spacing={0.5} sx={{ color: 'rgba(255,255,255,0.7)' }}>
              <Typography variant="body2">Premium Game Server Hosting</Typography>
              <Typography variant="body2">24/7 Support & 99.9% Uptime</Typography>
              <Typography variant="body2">Instant Setup & Deployment</Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                <Link 
                  href="mailto:support@jmfhosting.com" 
                  sx={{ 
                    ...linkStyles,
                    display: 'inline',
                  }}
                >
                  support@jmfhosting.com
                </Link>
              </Typography>
            </Stack>
          </Grid>

          {/* Games & Services */}
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ 
              mb: 2,
              color: '#fff',
              fontWeight: 'bold'
            }}>
              Games & Services
            </Typography>
            <Stack spacing={1} alignItems="center">
              {[
                { text: 'Minecraft Servers', href: '/store/plans/minecraft' },
                { text: 'Rust Servers', href: '/store/plans/rust' },
                { text: 'ARK Servers', href: '/store/plans/ark' },
                { text: 'VPS Hosting', href: '/store/plans/vps' }
              ].map((item) => (
                <Link
                  key={item.text}
                  href={item.href}
                  sx={linkStyles}
                >
                  {item.text}
                </Link>
              ))}
            </Stack>
          </Grid>

          {/* Support */}
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ 
              mb: 2,
              color: '#fff',
              fontWeight: 'bold'
            }}>
              Support
            </Typography>
            <Stack spacing={1} alignItems="center">
              {[
                { text: 'Server Status', href: '/status' },
                { text: 'Knowledge Base', href: '/support/kb' },
                { text: 'FAQ', href: '/support/faq' },
                { text: 'Contact Support', href: '/support/contact' }
              ].map((item) => (
                <Link
                  key={item.text}
                  href={item.href}
                  sx={linkStyles}
                >
                  {item.text}
                </Link>
              ))}
            </Stack>
          </Grid>

          {/* Connect With Us */}
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ 
              mb: 2,
              color: '#fff',
              fontWeight: 'bold'
            }}>
              Connect With Us
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center" sx={{ mb: 1 }}>
              <Link 
                href="https://www.youtube.com/jmfhosting" 
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: 'rgba(255,255,255,0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': { color: '#FF0000' }
                }}
              >
                <YouTube />
              </Link>
              <Link 
                href="https://twitter.com/jmfhosting" 
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: 'rgba(255,255,255,0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': { color: '#1DA1F2' }
                }}
              >
                <Twitter />
              </Link>
              <Link 
                href="https://github.com/jmfhosting" 
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: 'rgba(255,255,255,0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': { color: '#fff' }
                }}
              >
                <GitHub />
              </Link>
            </Stack>
            <Stack spacing={1} alignItems="center">
              {[
                { text: 'Terms of Service', href: '/legal/terms' },
                { text: 'Privacy Policy', href: '/legal/privacy' },
                { text: 'Security Policy', href: '/legal/security' }
              ].map((item) => (
                <Link
                  key={item.text}
                  href={item.href}
                  sx={linkStyles}
                >
                  {item.text}
                </Link>
              ))}
            </Stack>
          </Grid>
        </Grid>

        {/* Payment Methods Section */}
        <Grid item xs={12}>
          <Box sx={{ 
            textAlign: 'right',
            mt: 4,
            pt: 3,
            display: 'inline-block',
          }}>
            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)', mb: 1 }}>
              We Accept Payments:
            </Typography>
            <Box
              component="img"
              src="/images/payments/stripe.png"
              alt="Stripe"
              sx={{
                height: 30,
                filter: 'grayscale(100%)',
                opacity: 0.7,
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  filter: 'grayscale(0%)',
                  opacity: 1,
                  transform: 'scale(1.1)',
                  boxShadow: '0 0 10px rgba(76, 175, 80, 0.5)',
                }
              }}
            />
          </Box>
        </Grid>

        {/* Copyright */}
        <Box sx={{ 
          mt: 4, 
          pt: 3, 
          borderTop: '1px solid rgba(255,255,255,0.1)',
          textAlign: 'center'
        }}>
          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.5)' }}>
            © 2023 - 2025 JMF Hosting
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.5)', mt: 1 }}>
            All prices are shown in USD and include VAT where applicable
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer; 