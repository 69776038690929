class APILogger {
  constructor() {
    this.logs = [];
    this.maxLogs = 100;
  }

  logRequest(config) {
    const log = {
      timestamp: new Date(),
      type: 'request',
      method: config.method?.toUpperCase(),
      url: config.url,
      headers: config.headers,
      data: config.data,
      id: Math.random().toString(36).substring(7)
    };

    this.addLog(log);
    return log.id;
  }

  logResponse(response, requestId) {
    const log = {
      timestamp: new Date(),
      type: 'response',
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      data: response.data,
      requestId,
      duration: this.calculateDuration(requestId)
    };

    this.addLog(log);
  }

  logError(error, requestId) {
    const log = {
      timestamp: new Date(),
      type: 'error',
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText,
      headers: error.response?.headers,
      data: error.response?.data,
      requestId,
      duration: this.calculateDuration(requestId)
    };

    this.addLog(log);
  }

  addLog(log) {
    this.logs.unshift(log);
    if (this.logs.length > this.maxLogs) {
      this.logs.pop();
    }
  }

  calculateDuration(requestId) {
    const requestLog = this.logs.find(
      log => log.type === 'request' && log.id === requestId
    );
    if (requestLog) {
      return new Date() - requestLog.timestamp;
    }
    return null;
  }

  getLogs(filters = {}) {
    let filteredLogs = [...this.logs];

    if (filters.type) {
      filteredLogs = filteredLogs.filter(log => log.type === filters.type);
    }
    if (filters.status) {
      filteredLogs = filteredLogs.filter(log => log.status === filters.status);
    }
    if (filters.method) {
      filteredLogs = filteredLogs.filter(log => log.method === filters.method);
    }

    return filteredLogs;
  }

  clearLogs() {
    this.logs = [];
  }
}

export const apiLogger = new APILogger();
export default apiLogger; 