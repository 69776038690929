/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Container, Typography, Paper, Box, IconButton, Collapse } from '@mui/material';
import { 
  ExpandMore, ExpandLess, Security as SecurityIcon, Storage, Lock,
  Router, Warning, Gavel, Shield, Backup, Visibility,
  Group, CheckCircle, Update, Key, Timer, Assignment,
  Announcement, Search, Star
} from '@mui/icons-material';
import securityContent from '../../content/security.json';
import '../../styles/legal.css';

const bulletIcons = {
  Security: <SecurityIcon />,
  Storage: <Storage />,
  Lock: <Lock />,
  Router: <Router />,
  Shield: <Shield />,
  Backup: <Backup />,
  Key: <Key />,
  Timer: <Timer />,
  Warning: <Warning />,
  Assignment: <Assignment />,
  Group: <Group />,
  Announcement: <Announcement />,
  Gavel: <Gavel />,
  CheckCircle: <CheckCircle />,
  Search: <Search />,
  Star: <Star />,
  Update: <Update />,
  Visibility: <Visibility />
};

const sectionIcons = {
  'Infrastructure Security': <SecurityIcon />,
  'Data Security': <Storage />,
  'Access Control': <Lock />,
  'Incident Response': <Warning />,
  'Compliance': <Gavel />
};

const keyPoints = [
  { icon: <SecurityIcon sx={{ fontSize: 20 }} />, text: 'We employ industry-standard security measures to protect your data' },
  { icon: <Lock sx={{ fontSize: 20 }} />, text: 'Your data is encrypted both at rest and in transit' },
  { icon: <Shield sx={{ fontSize: 20 }} />, text: 'Regular security audits and updates ensure ongoing protection' }
];

const SecurityIntroduction = ({ content }) => (
  <Paper elevation={3} className="legal-section introduction">
    <Typography variant="h4" gutterBottom>
      Security Policy
    </Typography>
    <Typography variant="body1" paragraph>
      {content.introduction}
    </Typography>
    <Box className="key-points">
      {content.introduction.split('•').slice(1).map((point, index) => (
        <Box key={index} className="key-point">
          <Shield sx={{ mr: 1 }} />
          <Typography variant="body1">{point.trim()}</Typography>
        </Box>
      ))}
    </Box>
  </Paper>
);

const LegalSection = ({ section }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper elevation={3} className="legal-section">
      <Box className="section-header" onClick={handleToggle}>
        <Box className="section-title">
          {bulletIcons[section.icon]}
          <Typography variant="h6">{section.title}</Typography>
        </Box>
        <IconButton>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto">
        <Box className="section-content">
          {section.subsections.map((subsection) => (
            <Box key={subsection.id} className="subsection">
              <Typography variant="h6" className="subsection-title">
                {bulletIcons[subsection.icon]}
                {subsection.title}
              </Typography>
              {subsection.bulletPoints.map((point, index) => (
                <Box key={index} className="bullet-point">
                  {bulletIcons[point.icon]}
                  <Box>
                    <Typography variant="subtitle1">{point.title}</Typography>
                    <Typography variant="body2">{point.content}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Collapse>
    </Paper>
  );
};

const SecurityPolicy = () => {
  return (
    <div className="legal-container">
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <SecurityIntroduction content={securityContent} />
        <Box className="sections-container">
          {securityContent.sections.map((section) => (
            <LegalSection key={section.id} section={section} />
          ))}
        </Box>
        <Box className="legal-footer">
          <Typography variant="body2" color="textSecondary">
            Last Updated: {new Date(securityContent.lastUpdated).toLocaleDateString()}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Effective Date: {new Date(securityContent.effectiveDate).toLocaleDateString()}
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default SecurityPolicy; 