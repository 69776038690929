import axios from 'axios';
import apiLogger from './logger';
import requestQueue from './requestQueue';
import rateLimiter from './rateLimiter';

const API_BASE_URL = process.env.REACT_APP_WORKER_URL || 'https://www.jmfhosting.com';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

// Set rate limits for specific endpoints
rateLimiter.setLimit('/servers', 30); // 30 requests per minute
rateLimiter.setLimit('/servers/*/start', 10); // 10 starts per minute
rateLimiter.setLimit('/servers/*/stop', 10);
rateLimiter.setLimit('/servers/*/restart', 5);

// Add queue handling for specific endpoints
const queuedEndpoints = [
  '/servers/create',
  '/servers/*/backup',
  '/servers/*/restore'
];

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    const requestId = apiLogger.logRequest(config);
    config.requestId = requestId;

    // Check if endpoint needs queuing
    const shouldQueue = queuedEndpoints.some(pattern => {
      const regex = new RegExp(pattern.replace('*', '[^/]+'));
      return regex.test(config.url);
    });

    if (shouldQueue) {
      return requestQueue.add(config);
    }

    // Check rate limits
    try {
      await rateLimiter.checkLimit(config.url);
    } catch (error) {
      return Promise.reject(error);
    }

    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    apiLogger.logError(error);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    apiLogger.logResponse(response, response.config.requestId);
    return response;
  },
  (error) => {
    apiLogger.logError(error, error.config?.requestId);
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/auth/login';
    }
    return Promise.reject(error.response?.data || error);
  }
);

// Auth endpoints
export const authService = {
  register: async (userData) => {
    try {
      const response = await api.post('/auth/register', {
        username: userData.username,
        email: userData.email,
        password: userData.password,
        first_name: userData.firstName,
        last_name: userData.lastName
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  login: async (credentials) => {
    try {
      const response = await api.post('/auth/login', credentials);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    window.location.href = '/auth/login';
  },

  getProfile: async () => {
    try {
      const response = await api.get('/auth/profile');
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  initiateGoogleAuth: async () => {
    try {
      const state = generateRandomString(32);
      sessionStorage.setItem('oauth_state', state);
      
      const response = await api.post('/auth/google/init', {
        redirect_url: `${window.location.origin}/auth/callback`,
        state
      });
      
      if (response.data?.authUrl) {
        window.location.href = response.data.authUrl;
      } else {
        throw new Error('No authentication URL received');
      }
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  initiateGithubAuth: async () => {
    try {
      const state = generateRandomString(32);
      sessionStorage.setItem('oauth_state', state);
      
      const response = await api.post('/auth/github/init', {
        redirect_url: `${window.location.origin}/auth/callback`,
        state
      });
      
      if (response.data?.authUrl) {
        window.location.href = response.data.authUrl;
      } else {
        throw new Error('No authentication URL received');
      }
    } catch (error) {
      throw error.response?.data || error;
    }
  }
};

function generateRandomString(length) {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export const userService = {
  getProfile: () => api.get('/api/users/profile'),
  updateProfile: (profileData) => api.put('/api/users/profile', profileData),
  updatePassword: (data) => api.put('/api/users/password', data),
  uploadAvatar: (file) => {
    const formData = new FormData();
    formData.append('avatar', file);
    return api.post('/api/users/avatar', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  login: (credentials) => api.post('/auth/login', credentials),
  getCurrentUser: () => api.get('/auth/user'),
  verifyEmail: (token) => api.post('/auth/verify-email', { token }),
  resetPassword: (email) => api.post('/auth/reset-password', { email }),
  deleteAccount: () => api.delete('/api/users/account'),
  getNotifications: () => api.get('/api/users/notifications'),
  updateNotificationSettings: (settings) => api.put('/api/users/notifications/settings', settings),
};

export const serverService = {
  getServers: () => api.get('/servers'),
  getServerStatus: () => api.get('/servers/status'),
  createServer: (data) => api.post('/servers/create', data),
  getServerDetails: (serverId) => api.get(`/servers/${serverId}`),
  updateServer: (serverId, data) => api.put(`/servers/${serverId}`, data),
  deleteServer: (serverId) => api.delete(`/servers/${serverId}`),
  getStats: () => api.get('/servers/stats'),
  
  // Server actions
  startServer: (serverId) => api.post(`/servers/${serverId}/start`),
  stopServer: (serverId) => api.post(`/servers/${serverId}/stop`),
  restartServer: (serverId) => api.post(`/servers/${serverId}/restart`),
  
  // Server configuration
  updateConfig: (serverId, config) => api.put(`/servers/${serverId}/config`, config),
  
  // Monitoring
  getMetrics: (serverId) => api.get(`/servers/${serverId}/metrics`),
  getLogs: (serverId) => api.get(`/servers/${serverId}/logs`),
};

export const billingService = {
  getSubscription: () => api.get('/billing/subscription'),
  updateSubscription: (planId) => api.put('/billing/subscription', { planId }),
  cancelSubscription: () => api.delete('/billing/subscription'),
  
  getPaymentMethods: () => api.get('/billing/payment-methods'),
  addPaymentMethod: (paymentMethod) => api.post('/billing/payment-methods', paymentMethod),
  removePaymentMethod: (paymentMethodId) => api.delete(`/billing/payment-methods/${paymentMethodId}`),
  
  getInvoices: () => api.get('/billing/invoices'),
  getInvoice: (invoiceId) => api.get(`/billing/invoices/${invoiceId}`),
  
  addCredits: (amount) => api.post('/billing/credits/add', { amount }),
  getTransactions: () => api.get('/billing/transactions')
};

export const supportService = {
  getTickets: () => api.get('/support/tickets'),
  getTicket: (id) => api.get(`/support/tickets/${id}`),
  createTicket: (data) => api.post('/support/tickets', data),
  updateTicket: (id, data) => api.put(`/support/tickets/${id}`, data),
  closeTicket: (id) => api.post(`/support/tickets/${id}/close`),
  
  addComment: (ticketId, comment) => api.post(`/support/tickets/${ticketId}/comments`, comment),
  uploadAttachment: (ticketId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return api.post(`/support/tickets/${ticketId}/attachments`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }
};

export const analyticsService = {
  getServerStats: (serverId, timeRange) => 
    api.get(`/analytics/servers/${serverId}`, { params: { timeRange }}),
  getUserStats: (timeRange) => 
    api.get('/analytics/user', { params: { timeRange }}),
  getSystemStats: () => api.get('/analytics/system'),
  exportData: (type, params) => 
    api.get(`/analytics/export/${type}`, { params, responseType: 'blob' })
};

export const paymentService = {
  createPaymentIntent: (data) => 
    api.post('/payments/create-intent', data),
  
  createServer: (data) =>
    api.post('/servers/create', data)
};

export const registerUser = async (userData) => {
  try {
    console.log('Sending registration request with data:', userData);
    const response = await api.post('/auth/register', {
      username: userData.username,
      email: userData.email,
      password: userData.password,
      firstName: userData.firstName || null,
      lastName: userData.lastName || null
    });
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);

    if (error.response) {
      throw {
        success: false,
        message: error.response.data.message || 'An error occurred during registration.',
      };
    } else if (error.request) {
      throw {
        success: false,
        message: 'No response received from server. Please try again.',
      };
    } else {
      throw {
        success: false,
        message: 'An unexpected error occurred.',
      };
    }
  }
};

export const resendVerification = async (email) => {
  try {
    const response = await api.post('/auth/resend-verification', { email });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export default api; 