import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import Logger from '../../utils/logger';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleOAuthCallback } = useAuth();

  useEffect(() => {
    const processOAuthCallback = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');
      const error = params.get('error');
      const provider = sessionStorage.getItem('oauth_provider') || 'unknown';

      Logger.network.requestStart(location.pathname, 'GET');

      try {
        if (error) {
          Logger.auth.oauthCallbackError(provider, error);
          throw new Error(error);
        }

        if (!code) {
          Logger.auth.oauthCallbackError(provider, 'No authorization code received');
          throw new Error('No authorization code received');
        }

        // Verify state matches
        const storedState = sessionStorage.getItem('oauth_state');
        Logger.formatMessage('🔍', 'OAuth', 'Verifying OAuth state', {
          stored: storedState,
          received: state
        });
        
        if (!state || state !== storedState) {
          Logger.auth.oauthStateError(provider, storedState, state);
          throw new Error('Invalid state parameter');
        }

        // Get the stored redirect URI
        const redirectUri = sessionStorage.getItem('oauth_redirect_uri') || 
          `${window.location.origin}/auth/callback`;

        try {
          const response = await handleOAuthCallback(
            provider,
            code,
            state,
            redirectUri
          );

          Logger.auth.oauthSuccess(provider, response.user?.email || 'unknown');
          Logger.network.requestSuccess(location.pathname, 'GET', response);

          if (response.token) {
            localStorage.setItem('token', response.token);
            sessionStorage.removeItem('oauth_state');
            sessionStorage.removeItem('oauth_provider');
            sessionStorage.removeItem('oauth_redirect_uri');
            navigate('/dashboard');
          }
        } catch (error) {
          Logger.auth.oauthCallbackError(provider, error, {
            code,
            redirectUri,
            response: error.response
          });

          if (error.response) {
            Logger.network.apiError(
              location.pathname,
              error.response.status,
              error.response.data
            );
          }

          throw error;
        }
      } catch (error) {
        if (error.response?.status === 405) {
          Logger.network.apiError(
            location.pathname,
            405,
            'Method not allowed for OAuth callback'
          );
        } else if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
          Logger.network.corsError(location.pathname, error);
        } else {
          Logger.network.requestFailed(location.pathname, 'GET', error);
        }

        navigate('/auth/login', {
          state: { error: 'Authentication failed. Please try again.' }
        });
      }
    };

    processOAuthCallback();
  }, [location, navigate, handleOAuthCallback]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="60vh"
      gap={2}
    >
      <CircularProgress />
      <Typography variant="body1" color="textSecondary">
        Completing your sign in...
      </Typography>
    </Box>
  );
};

export default OAuthCallback; 