import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  CreditCard as CreditCardIcon,
  Receipt as ReceiptIcon,
  CheckCircle as CheckCircleIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Refresh as RefreshIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  AttachMoney as MoneyIcon,
  AccountBalance as AccountIcon,
  History as HistoryIcon
} from '@mui/icons-material';
import { billingService } from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import StripeBuyButtonExample from '../../components/payment/StripeBuyButtonExample';
import '../../styles/billing.css';

const Billing = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchBillingData();
  }, []);

  const fetchBillingData = async () => {
    try {
      const [subscriptionRes, paymentMethodsRes, invoicesRes] = await Promise.all([
        billingService.getSubscription(),
        billingService.getPaymentMethods(),
        billingService.getInvoices()
      ]);

      setSubscription(subscriptionRes.data);
      setPaymentMethods(paymentMethodsRes.data || []);
      setInvoices(invoicesRes.data || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching billing data:', err);
      setError('Failed to load billing information. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddPaymentMethod = () => {
    // Implementation for adding a payment method
  };

  const handleRemovePaymentMethod = (id) => {
    // Implementation for removing a payment method
  };

  const handleChangePlan = (planId) => {
    // Implementation for changing a plan
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Billing & Subscription
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {success && (
          <Alert severity="success" sx={{ mb: 3 }}>
            {success}
          </Alert>
        )}
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            {/* Current Subscription */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <MoneyIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography variant="h6">Current Subscription</Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                
                {subscription ? (
                  <>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Plan: <strong>{subscription.planName}</strong>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Status: <Chip 
                          size="small" 
                          label={subscription.status} 
                          color={subscription.status === 'active' ? 'success' : 'default'} 
                        />
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Price: {formatCurrency(subscription.amount)} / {subscription.interval}
                      </Typography>
                      <Typography variant="body2">
                        Next billing date: {formatDate(subscription.currentPeriodEnd)}
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
                      <Button 
                        variant="outlined" 
                        size="small"
                        onClick={() => handleChangePlan(subscription.id)}
                      >
                        Change Plan
                      </Button>
                      <Button 
                        variant="outlined" 
                        size="small" 
                        color="error"
                      >
                        Cancel Subscription
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Box sx={{ textAlign: 'center', py: 3 }}>
                    <Typography variant="body1" gutterBottom>
                      You don't have an active subscription.
                    </Typography>
                    <Button 
                      variant="contained" 
                      color="primary"
                      sx={{ mt: 2 }}
                      href="/store"
                    >
                      View Plans
                    </Button>
                  </Box>
                )}
              </Paper>
            </Grid>
            
            {/* Payment Methods */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <CreditCardIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography variant="h6">Payment Methods</Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                
                {paymentMethods.length > 0 ? (
                  <>
                    {paymentMethods.map((method) => (
                      <Box 
                        key={method.id} 
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'center',
                          mb: 2,
                          p: 1,
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 1
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <CreditCardIcon sx={{ mr: 1 }} />
                          <Box>
                            <Typography variant="body1">
                              {method.brand} •••• {method.last4}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Expires {method.expMonth}/{method.expYear}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          {method.isDefault && (
                            <Chip 
                              size="small" 
                              label="Default" 
                              color="primary" 
                              sx={{ mr: 1 }} 
                            />
                          )}
                          <IconButton 
                            size="small"
                            onClick={() => handleRemovePaymentMethod(method.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box sx={{ textAlign: 'center', py: 3 }}>
                    <Typography variant="body1" gutterBottom>
                      No payment methods found.
                    </Typography>
                  </Box>
                )}
                
                <Button 
                  variant="contained" 
                  startIcon={<AddIcon />}
                  onClick={handleAddPaymentMethod}
                  sx={{ mt: 2 }}
                >
                  Add Payment Method
                </Button>
              </Paper>
            </Grid>
            
            {/* Stripe Buy Button Example */}
            <Grid item xs={12}>
              <StripeBuyButtonExample 
                buyButtonId={process.env.REACT_APP_STRIPE_BUY_BUTTON_ID || 'buy_btn_1Qf3D6BnDzJLjON9FuWDtBJv'}
                publishableKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_51Qf3D6BnDzJLjON9FuWDtBJvUwrCWq0mfABfm9yTbE4dMYTfxP7YCVfrKaHwFNrNmoTWTK9oKHtrPHAeEYvXnNCi00TIhkCHbC'}
                title="Purchase Game Server"
                description="Use our Stripe Buy Button with custom attributes to purchase a game server. This example demonstrates how to use client-reference-id, customer-email, and customer-session-client-secret."
              />
            </Grid>
            
            {/* Billing History */}
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <HistoryIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography variant="h6">Billing History</Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                
                {invoices.length > 0 ? (
                  <>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="right">Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoices
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((invoice) => (
                              <TableRow key={invoice.id}>
                                <TableCell>{formatDate(invoice.date)}</TableCell>
                                <TableCell>{invoice.description}</TableCell>
                                <TableCell>{formatCurrency(invoice.amount)}</TableCell>
                                <TableCell>
                                  <Chip 
                                    size="small" 
                                    label={invoice.status} 
                                    color={invoice.status === 'paid' ? 'success' : 'default'} 
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip title="Download Invoice">
                                    <IconButton size="small">
                                      <ReceiptIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={invoices.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(e, newPage) => setPage(newPage)}
                      onRowsPerPageChange={(e) => {
                        setRowsPerPage(parseInt(e.target.value, 10));
                        setPage(0);
                      }}
                    />
                  </>
                ) : (
                  <Box sx={{ textAlign: 'center', py: 3 }}>
                    <Typography variant="body1">
                      No billing history available.
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default Billing; 