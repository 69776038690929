import React from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme
} from '@mui/material';
import {
  People as PeopleIcon,
  Computer as ComputerIcon,
  AttachMoney as RevenueIcon,
  Warning as AlertIcon
} from '@mui/icons-material';
import Navbar from '../../components/layout/Navbar';

const AdminDashboard = () => {
  const theme = useTheme();
  
  return (
    <>
      <Navbar />
      <Box sx={{ 
        flexGrow: 1, 
        width: '100%', 
        pt: 8,
        pb: 4,
        backgroundColor: 'rgba(0,0,0,0.9)'
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {/* Stats Cards */}
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Total Users
                  </Typography>
                  <Typography variant="h4">2,453</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PeopleIcon color="primary" />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      +12% this month
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Active Servers
                  </Typography>
                  <Typography variant="h4">1,234</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ComputerIcon color="primary" />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      98.5% uptime
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Monthly Revenue
                  </Typography>
                  <Typography variant="h4">$12,345</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <RevenueIcon color="primary" />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      +8% vs last month
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Active Alerts
                  </Typography>
                  <Typography variant="h4">3</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AlertIcon color="error" />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      2 critical
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Recent Activity */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Recent Activity
                </Typography>
                {/* Add activity list here */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AdminDashboard; 