class RateLimiter {
  constructor() {
    this.limits = new Map();
    this.requests = new Map();
    this.defaultLimit = 60; // requests per minute
    this.defaultWindow = 60000; // 1 minute in milliseconds
  }

  setLimit(endpoint, limit, window = this.defaultWindow) {
    this.limits.set(endpoint, { limit, window });
  }

  async checkLimit(endpoint) {
    const now = Date.now();
    const limit = this.limits.get(endpoint) || { limit: this.defaultLimit, window: this.defaultWindow };
    
    // Get or initialize request history
    let requests = this.requests.get(endpoint) || [];
    requests = requests.filter(time => time > now - limit.window);
    
    if (requests.length >= limit.limit) {
      const oldestRequest = requests[0];
      const resetTime = oldestRequest + limit.window;
      throw new Error(`Rate limit exceeded. Try again after ${new Date(resetTime).toLocaleTimeString()}`);
    }

    requests.push(now);
    this.requests.set(endpoint, requests);
    return true;
  }

  getRemainingRequests(endpoint) {
    const now = Date.now();
    const limit = this.limits.get(endpoint) || { limit: this.defaultLimit, window: this.defaultWindow };
    const requests = this.requests.get(endpoint) || [];
    const validRequests = requests.filter(time => time > now - limit.window);
    return limit.limit - validRequests.length;
  }

  getResetTime(endpoint) {
    const requests = this.requests.get(endpoint) || [];
    if (requests.length === 0) return null;
    
    const limit = this.limits.get(endpoint) || { window: this.defaultWindow };
    return new Date(requests[0] + limit.window);
  }
}

export const rateLimiter = new RateLimiter();
export default rateLimiter; 