// import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
// AnimatePresence is imported but not used, so we'll remove it
import { Box, Typography, IconButton } from '@mui/material';
import {
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  Close as CloseIcon
} from '@mui/icons-material';

// Mock toast function
const toast = (content, options) => {
  console.log('Toast notification:', options?.type, content);
  return { id: Math.random() };
};

// Animation variants for notifications
const notificationVariants = {
  initial: { opacity: 0, y: -50, scale: 0.95 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, x: 100, scale: 0.95 }
};

// Custom notification component
const NotificationContent = ({ icon: Icon, title, message, type }) => (
  <Box
    component={motion.div}
    variants={notificationVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      gap: 1,
      minWidth: '300px',
      maxWidth: '400px',
      bgcolor: 'background.paper',
      borderRadius: 1,
      boxShadow: 3,
      p: 1.5,
      border: '1px solid',
      borderColor: type === 'success' ? 'success.main' :
                  type === 'error' ? 'error.main' :
                  type === 'warning' ? 'warning.main' : 'info.main'
    }}
  >
    <Icon 
      color={type} 
      sx={{ 
        fontSize: 24,
        mt: 0.5
      }} 
    />
    <Box sx={{ flex: 1 }}>
      <Typography variant="subtitle2" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {message}
      </Typography>
    </Box>
  </Box>
);

// Template definitions
const templates = {
  // Server related notifications
  server_created: {
    title: 'Server Created',
    message: 'Your server has been successfully created and is being provisioned.',
    type: 'success',
    icon: SuccessIcon,
    duration: 5000
  },
  server_ready: {
    title: 'Server Ready',
    message: 'Your server is now ready to use!',
    type: 'success',
    icon: SuccessIcon,
    duration: 5000
  },
  server_error: {
    title: 'Server Error',
    message: 'An error occurred with your server. Our team has been notified.',
    type: 'error',
    icon: ErrorIcon,
    duration: null // Stays until dismissed
  },
  server_maintenance: {
    title: 'Scheduled Maintenance',
    message: 'Server maintenance is scheduled for {time}.',
    type: 'info',
    icon: InfoIcon,
    duration: 7000
  },
  server_backup_complete: {
    title: 'Backup Complete',
    message: 'Server backup has been completed successfully.',
    type: 'success',
    icon: SuccessIcon,
    duration: 5000
  },

  // Payment related notifications
  payment_success: {
    title: 'Payment Successful',
    message: 'Your payment has been processed successfully.',
    type: 'success',
    icon: SuccessIcon,
    duration: 5000
  },
  payment_failed: {
    title: 'Payment Failed',
    message: 'Your payment could not be processed. Please try again.',
    type: 'error',
    icon: ErrorIcon,
    duration: null
  },
  subscription_renewed: {
    title: 'Subscription Renewed',
    message: 'Your subscription has been renewed successfully.',
    type: 'success',
    icon: SuccessIcon,
    duration: 5000
  },

  // Authentication related notifications
  auth_success: {
    title: 'Welcome Back',
    message: 'You have been successfully logged in.',
    type: 'success',
    icon: SuccessIcon,
    duration: 3000
  },
  auth_error: {
    title: 'Authentication Error',
    message: 'Failed to authenticate. Please try again.',
    type: 'error',
    icon: ErrorIcon,
    duration: 5000
  },
  session_expired: {
    title: 'Session Expired',
    message: 'Your session has expired. Please log in again.',
    type: 'warning',
    icon: WarningIcon,
    duration: null
  }
};

// Helper function to format template messages
const formatMessage = (message, params = {}) => {
  return message.replace(/\{(\w+)\}/g, (match, key) => params[key] || match);
};

// Main notification function
const notify = (templateName, params = {}, options = {}) => {
  const template = templates[templateName];
  if (!template) {
    console.error(`Template "${templateName}" not found`);
    return;
  }

  const message = formatMessage(template.message, params);
  
  return toast(
    ({ closeToast }) => (
      <NotificationContent
        icon={template.icon}
        title={template.title}
        message={message}
        type={template.type}
        onClose={closeToast}
      />
    ),
    {
      type: template.type,
      autoClose: template.duration,
      closeButton: ({ closeToast }) => (
        <IconButton 
          size="small" 
          onClick={closeToast}
          sx={{ 
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary'
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      ...options
    }
  );
};

// Export individual notification functions
export const Notifications = {
  serverCreated: (params) => notify('server_created', params),
  serverReady: (params) => notify('server_ready', params),
  serverError: (params) => notify('server_error', params),
  serverMaintenance: (params) => notify('server_maintenance', params),
  serverBackupComplete: (params) => notify('server_backup_complete', params),
  paymentSuccess: (params) => notify('payment_success', params),
  paymentFailed: (params) => notify('payment_failed', params),
  subscriptionRenewed: (params) => notify('subscription_renewed', params),
  authSuccess: (params) => notify('auth_success', params),
  authError: (params) => notify('auth_error', params),
  sessionExpired: (params) => notify('session_expired', params),
  
  // Custom notification
  custom: (title, message, type = 'info', duration = 5000) => {
    const icons = {
      success: SuccessIcon,
      error: ErrorIcon,
      warning: WarningIcon,
      info: InfoIcon
    };
    
    return notify('custom', {}, {
      render: () => (
        <NotificationContent
          icon={icons[type]}
          title={title}
          message={message}
          type={type}
        />
      ),
      autoClose: duration
    });
  }
}; 