import React from 'react';
import { Box, Typography, Button, Card, CardContent } from '@mui/material';

const GameCard = ({ game, onSelect }) => {
  const [loading, setLoading] = React.useState(true);

  // Preload images
  React.useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = game.backgroundImage;
    backgroundImage.onload = () => setLoading(false);

    const logoImage = new Image();
    logoImage.src = game.logoImage;
    logoImage.onload = () => setLoading(false);
  }, [game]);

  return (
    <Card sx={{ 
      maxWidth: 345, 
      margin: '16px', 
      transition: 'transform 0.2s', 
      '&:hover': { transform: 'scale(1.05)' },
      opacity: loading ? 0.7 : 1
    }}>
      <Box 
        sx={{ 
          backgroundImage: `url(${game.backgroundImage})`, 
          height: 140, 
          backgroundSize: 'cover', 
          borderRadius: '8px 8px 0 0',
          filter: loading ? 'blur(4px)' : 'none'
        }}
      />
      <CardContent>
        <Typography variant="h5" component="div">
          {game.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {game.description}
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => onSelect(game.id)} 
          sx={{ marginTop: 2 }}
          disabled={loading}
        >
          Select Plan
        </Button>
      </CardContent>
    </Card>
  );
};

export default GameCard; 