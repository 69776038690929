import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ResourceUsageChart = ({ title, data, metrics = ['cpu', 'memory'] }) => {
  const theme = useTheme();

  if (!data) {
    return (
      <Box sx={{ 
        height: 300, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        color: 'rgba(255, 255, 255, 0.5)'
      }}>
        No data available
      </Box>
    );
  }

  const metricColors = {
    cpu: {
      line: '#2196F3',
      fill: 'rgba(33, 150, 243, 0.1)'
    },
    memory: {
      line: '#FF4081',
      fill: 'rgba(255, 64, 129, 0.1)'
    },
    network: {
      line: '#FFC107',
      fill: 'rgba(255, 193, 7, 0.1)'
    },
    storage: {
      line: '#4CAF50',
      fill: 'rgba(76, 175, 80, 0.1)'
    }
  };

  const metricLabels = {
    cpu: 'CPU Usage',
    memory: 'Memory Usage',
    network: 'Network Usage',
    storage: 'Storage Usage'
  };

  const chartData = {
    labels: data[metrics[0]]?.map((_, index) => `${index * 5}m`) || [],
    datasets: metrics.map(metric => ({
      label: metricLabels[metric],
      data: data[metric] || [],
      borderColor: metricColors[metric].line,
      backgroundColor: metricColors[metric].fill,
      fill: true,
      tension: 0.4,
      pointRadius: 0,
      pointHitRadius: 10,
      borderWidth: 2
    }))
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: theme.typography.fontFamily
          },
          boxWidth: 15,
          usePointStyle: true
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: '#fff',
        bodyColor: '#fff',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        padding: 10,
        bodyFont: {
          family: theme.typography.fontFamily
        },
        titleFont: {
          family: theme.typography.fontFamily,
          weight: 'bold'
        },
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return `${label}: ${value}%`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
          drawBorder: false
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: theme.typography.fontFamily
          }
        }
      },
      y: {
        min: 0,
        max: 100,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
          drawBorder: false
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: theme.typography.fontFamily
          },
          callback: (value) => `${value}%`
        }
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    }
  };

  return (
    <Box sx={{ height: 300, p: 2 }}>
      {title && (
        <Typography 
          variant="h6" 
          sx={{ 
            color: 'rgba(255, 255, 255, 0.9)',
            mb: 2,
            fontWeight: 500
          }}
        >
          {title}
        </Typography>
      )}
      <Line data={chartData} options={options} />
    </Box>
  );
};

export default ResourceUsageChart; 