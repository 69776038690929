import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Button,
  Alert,
  IconButton,
  useTheme,
  Tooltip,
  Card,
  CardContent,
  LinearProgress,
  Divider
} from '@mui/material';
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  Memory as MemoryIcon,
  Storage as StorageIcon,
  Speed as SpeedIcon,
  People as PeopleIcon,
  SportsEsports as GamingIcon,
  NetworkCheck as NetworkIcon,
  Timer as UptimeIcon,
  Warning as WarningIcon
} from '@mui/icons-material';
import { serverService } from '../../services/api';
import ServerStatusCard from '../../components/dashboard/ServerStatusCard';
import ResourceUsageChart from '../../components/dashboard/ResourceUsageChart';
import { useAuth } from '../../context/AuthContext';
import '../../styles/dashboard.css';

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [servers, setServers] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setRefreshing(true);
      const [serversResponse, statsResponse] = await Promise.all([
        serverService.getServers(),
        serverService.getStats()
      ]);
      
      setServers(serversResponse.data || []);
      setStats(statsResponse.data || {
        totalPlayers: 0,
        resourceUsage: {
          cpu: [],
          memory: [],
          storage: [],
          network: []
        },
        uptime: 0,
        alerts: []
      });
      setError(null);
    } catch (err) {
      console.error('Dashboard data fetch error:', err);
      setError('Failed to load dashboard data. Please try again.');
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleCreateServer = () => {
    navigate('/servers/create');
  };

  const getUptimeColor = (uptime) => {
    if (uptime >= 99) return '#4CAF50';
    if (uptime >= 95) return '#FFC107';
    return '#f44336';
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dashboard
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5">
                Your Game Servers
              </Typography>
              <Box>
                <Tooltip title="Refresh data">
                  <IconButton onClick={fetchDashboardData} disabled={refreshing}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Button 
                  variant="contained" 
                  color="primary" 
                  startIcon={<AddIcon />}
                  onClick={handleCreateServer}
                  disabled={user?.serverLimit <= servers.length}
                >
                  New Server
                </Button>
              </Box>
            </Box>
            
            <Grid container spacing={3}>
              {/* Quick Stats */}
              <Grid item xs={12} md={3}>
                <Paper className="dashboard-card dashboard-stat-card">
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <GamingIcon sx={{ color: '#2196F3', mr: 1 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
                      Active Servers
                    </Typography>
                  </Box>
                  <Typography variant="h3" sx={{ color: '#2196F3', fontWeight: 700 }}>
                    {servers.filter(s => s.status === 'running').length}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 1 }}>
                    of {servers.length} total servers
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={3}>
                <Paper className="dashboard-card dashboard-stat-card">
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <PeopleIcon sx={{ color: '#4CAF50', mr: 1 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
                      Total Players
                    </Typography>
                  </Box>
                  <Typography variant="h3" sx={{ color: '#4CAF50', fontWeight: 700 }}>
                    {stats?.totalPlayers || 0}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 1 }}>
                    across all servers
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={3}>
                <Paper className="dashboard-card dashboard-stat-card">
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <UptimeIcon sx={{ color: '#FF4081', mr: 1 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
                      Average Uptime
                    </Typography>
                  </Box>
                  <Typography variant="h3" sx={{ 
                    color: getUptimeColor(stats?.uptime || 0), 
                    fontWeight: 700 
                  }}>
                    {stats?.uptime || 0}%
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 1 }}>
                    last 30 days
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={3}>
                <Paper className="dashboard-card dashboard-stat-card">
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <NetworkIcon sx={{ color: '#FFC107', mr: 1 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
                      Network Usage
                    </Typography>
                  </Box>
                  <Typography variant="h3" sx={{ color: '#FFC107', fontWeight: 700 }}>
                    {stats?.networkUsage?.total || '0'} GB
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 1 }}>
                    total bandwidth used
                  </Typography>
                </Paper>
              </Grid>

              {/* System Alerts */}
              {stats?.alerts?.length > 0 && (
                <Grid item xs={12}>
                  <Paper className="dashboard-card">
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <WarningIcon sx={{ color: '#FFC107', mr: 1 }} />
                      <Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
                        System Alerts
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {stats.alerts.map((alert, index) => (
                        <Grid item xs={12} md={4} key={index}>
                          <Alert 
                            severity={alert.severity} 
                            sx={{ 
                              '& .MuiAlert-message': { color: '#fff' },
                              backgroundColor: 'rgba(255, 255, 255, 0.05)'
                            }}
                          >
                            {alert.message}
                          </Alert>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {/* Server List */}
              <Grid item xs={12}>
                <Paper className="dashboard-card">
                  <Typography variant="h5" sx={{ color: '#fff', fontWeight: 600, mb: 3 }}>
                    Your Gaming Servers
                  </Typography>
                  <Grid container spacing={3}>
                    {servers.length === 0 ? (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            textAlign: 'center',
                            py: 6,
                            color: 'rgba(255, 255, 255, 0.7)'
                          }}
                        >
                          <GamingIcon sx={{ fontSize: 60, mb: 2, color: 'rgba(255, 255, 255, 0.2)' }} />
                          <Typography variant="h6" sx={{ mb: 2 }}>
                            No servers found
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 3, color: 'rgba(255, 255, 255, 0.5)' }}>
                            Deploy your first gaming server to get started
                          </Typography>
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleCreateServer}
                            className="dashboard-button"
                          >
                            Deploy New Server
                          </Button>
                        </Box>
                      </Grid>
                    ) : (
                      servers.map(server => (
                        <Grid item xs={12} md={6} lg={4} key={server._id}>
                          <ServerStatusCard server={server} onRefresh={fetchDashboardData} />
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Paper>
              </Grid>

              {/* Resource Usage Charts */}
              <Grid item xs={12}>
                <Paper className="dashboard-card">
                  <Typography variant="h5" sx={{ color: '#fff', fontWeight: 600, mb: 3 }}>
                    Resource Usage
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <ResourceUsageChart 
                        title="CPU & Memory Usage"
                        data={stats?.resourceUsage}
                        metrics={['cpu', 'memory']}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ResourceUsageChart 
                        title="Network & Storage Usage"
                        data={stats?.resourceUsage}
                        metrics={['network', 'storage']}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Quick Actions */}
              <Grid item xs={12}>
                <Paper className="dashboard-card">
                  <Typography variant="h5" sx={{ color: '#fff', fontWeight: 600, mb: 3 }}>
                    Quick Actions
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<SpeedIcon />}
                        onClick={() => navigate('/servers/performance')}
                        sx={{ color: '#fff', borderColor: 'rgba(255, 255, 255, 0.2)' }}
                      >
                        Performance Metrics
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<StorageIcon />}
                        onClick={() => navigate('/servers/backups')}
                        sx={{ color: '#fff', borderColor: 'rgba(255, 255, 255, 0.2)' }}
                      >
                        Manage Backups
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<MemoryIcon />}
                        onClick={() => navigate('/servers/mods')}
                        sx={{ color: '#fff', borderColor: 'rgba(255, 255, 255, 0.2)' }}
                      >
                        Mod Manager
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<PeopleIcon />}
                        onClick={() => navigate('/servers/players')}
                        sx={{ color: '#fff', borderColor: 'rgba(255, 255, 255, 0.2)' }}
                      >
                        Player Management
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Dashboard; 