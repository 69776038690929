// Constants for log types and emojis
const LOG_TYPES = {
  REACT: 'React',
  STRIPE: 'Stripe',
  OAUTH: 'OAuth',
  I18N: 'i18n',
  NETWORK: 'Network',
  PERFORMANCE: 'Performance',
  SECURITY: 'Security',
  GENERAL: 'General'
};

const EMOJIS = {
  INFO: '📝',
  WARNING: '⚠️',
  ERROR: '❌',
  DEBUG: '🔍',
  NETWORK: '📡',
  SECURITY: '🔒',
  PERFORMANCE: '⚡',
  SUCCESS: '✅',
  REACT: '⚛️',
  STRIPE: '💳',
  OAUTH: '🔑',
  I18N: '🌐'
};

const AUTH_EVENTS = {
  LOGIN_ATTEMPT: '🔑',
  LOGIN_SUCCESS: '🎉',
  LOGIN_FAILED: '🚫',
  LOGOUT: '👋',
  REGISTER_ATTEMPT: '📝',
  REGISTER_SUCCESS: '🎊',
  REGISTER_FAILED: '💔',
  PASSWORD_RESET: '🔄',
  EMAIL_VERIFICATION: '✉️',
  SESSION_EXPIRED: '⏰',
  OAUTH_START: '🔗',
  OAUTH_SUCCESS: '🌟',
  OAUTH_FAILED: '💥'
};

const PAYMENT_EVENTS = {
  PAYMENT_ATTEMPT: '💳',
  PAYMENT_SUCCESS: '💰',
  PAYMENT_FAILED: '💸',
  SUBSCRIPTION_CREATED: '📅',
  SUBSCRIPTION_RENEWED: '🔄',
  SUBSCRIPTION_CANCELLED: '🛑',
  REFUND_ISSUED: '⏮️',
  PAYMENT_PENDING: '⏳',
  PAYMENT_DISPUTED: '⚠️'
};

const NETWORK_EVENTS = {
  REQUEST_START: '📡',
  REQUEST_SUCCESS: '✨',
  REQUEST_FAILED: '💢',
  CORS_ERROR: '🚧',
  API_ERROR: '⚡',
  TIMEOUT: '⏱️',
  NO_INTERNET: '📵',
  INVALID_RESPONSE: '❓'
};

class Logger {
  static LOG_TYPES = LOG_TYPES;
  static EMOJIS = EMOJIS;

  static styles = {
    header: 'font-weight: bold; color: #2196F3;',
    error: 'color: #f44336;',
    warning: 'color: #ff9800;',
    success: 'color: #4caf50;',
    info: 'color: #2196F3;',
    debug: 'color: #9e9e9e;'
  };

  static formatError(error) {
    return {
      name: error?.name || 'Unknown Error',
      message: error?.message || 'An unknown error occurred',
      code: error?.code,
      stack: error?.stack,
      details: error?.details || {},
      timestamp: new Date().toISOString()
    };
  }

  static getErrorSuggestion(error) {
    const suggestions = {
      'NO_I18NEXT_INSTANCE': [
        'Initialize i18next using initReactI18next',
        'Check i18n configuration in App.jsx',
        'Verify i18n instance is properly passed to I18nextProvider'
      ],
      'ERR_CONNECTION_REFUSED': [
        'Check if the server is running',
        'Verify API endpoint configuration',
        'Check network connectivity',
        'Ensure correct port configuration'
      ],
      'Invalid state parameter': [
        'Verify OAuth state parameter handling',
        'Check session storage for state',
        'Ensure OAuth callback URLs are correctly configured',
        'Review OAuth flow implementation'
      ],
      'STRIPE_ERROR': [
        'Verify Stripe public key configuration',
        'Ensure HTTPS is used in production',
        'Check Stripe.js integration',
        'Review payment flow implementation'
      ]
    };

    return suggestions[error?.code] || [
      'Check the error details above',
      'Review server logs for more information',
      'Verify request parameters and headers'
    ];
  }

  static log(type, message, data = null) {
    const timestamp = new Date().toISOString();
    const emoji = EMOJIS[type] || EMOJIS.INFO;
    
    console.log(`${emoji} [${timestamp}] ${message}`);
    
    if (data) {
      console.log('Details:', data);
    }
  }

  static warn(message, data = null) {
    this.log('WARNING', message, data);
  }

  static error(message, error = null) {
    this.log('ERROR', message, error ? this.formatError(error) : null);
  }

  static info(message, data = null) {
    this.log('INFO', message, data);
  }

  static debug(message, data = null) {
    this.log('DEBUG', message, data);
  }

  static network = {
    request: (config) => {
      Logger.log('NETWORK', `${config.method?.toUpperCase()} request to ${config.url}`, {
        url: config.url,
        method: config.method?.toUpperCase(),
        headers: config.headers,
        data: config.data
      });
    },

    response: (response) => {
      Logger.log('SUCCESS', `Response from ${response.config.url}`, {
        status: response.status,
        data: response.data,
        headers: response.headers
      });
    },

    error: (error) => {
      const formattedError = Logger.formatError(error);
      Logger.error(`${error.config?.method?.toUpperCase()} request to ${error.config?.url} failed`, formattedError);
    },
    
    requestStart: (url, method) => {
      Logger.log('NETWORK', `${method} request started to ${url}`, {
        url,
        method
      });
    },
    
    requestSuccess: (url, method, data) => {
      Logger.log('SUCCESS', `${method} request to ${url} succeeded`, {
        url,
        method,
        data
      });
    },
    
    requestFailed: (url, method, error) => {
      const formattedError = Logger.formatError(error);
      Logger.error(`${method} request to ${url} failed`, formattedError);
    },
    
    apiError: (url, status, data) => {
      Logger.error(`API error (${status}) for ${url}`, {
        url,
        status,
        data
      });
    },
    
    corsError: (url, error) => {
      Logger.error(`CORS error for ${url}`, {
        url,
        error: Logger.formatError(error),
        suggestion: 'Check that the API server is running and CORS is properly configured'
      });
    }
  };

  static react = {
    devTools: () => {
      Logger.warn('React DevTools not installed', {
        suggestion: 'Install React DevTools for better debugging experience',
        url: 'https://reactjs.org/link/react-devtools'
      });
    },

    error: (error) => {
      Logger.error('React error occurred', error);
    }
  };

  static i18n = {
    error: (error) => {
      Logger.error('Internationalization error', error);
    }
  };

  static stripe = {
    warning: (message) => {
      Logger.warn(message, {
        suggestions: [
          'Use HTTPS in production',
          'Verify Stripe.js integration',
          'Check Stripe public key configuration'
        ]
      });
    },

    error: (error) => {
      Logger.error('Stripe error occurred', error);
    }
  };

  static oauth = {
    error: (error, context = {}) => {
      Logger.error('OAuth error occurred', {
        ...Logger.formatError(error),
        context
      });
    }
  };

  static performance = {
    warning: (resource) => {
      Logger.warn(`Resource ${resource} was preloaded but not used`, {
        suggestions: [
          'Review preload strategy',
          'Check resource usage',
          'Consider removing unused preloads'
        ]
      });
    }
  };

  // Auth Logging
  static auth = {
    loginAttempt: (email) => {
      Logger.log('AUTH', `Login attempt for ${email}`);
    },
    loginSuccess: (username) => {
      Logger.log('AUTH', `${username} logged in successfully`);
    },
    loginFailed: (email, error) => {
      Logger.error('Login failed for', { email, error });
    },
    logout: (username) => {
      Logger.log('AUTH', `${username} logged out`);
    },
    registerAttempt: (email) => {
      Logger.log('AUTH', `Registration attempt for ${email}`);
    },
    registerSuccess: (email) => {
      Logger.log('AUTH', `Registration successful for ${email}`);
    },
    registerFailed: (email, error) => {
      Logger.error('Registration failed for', { email, error });
    },
    oauthStart: (provider) => {
      Logger.log('AUTH', `Starting ${provider} OAuth flow`);
    },
    oauthSuccess: (provider, email) => {
      Logger.log('AUTH', `${provider} OAuth successful for ${email}`);
    },
    oauthFailed: (provider, error) => {
      Logger.error(`${provider} OAuth failed`, { error });
    },
    oauthCallbackError: (provider, error, details = null) => {
      Logger.error(`${provider} callback failed`, {
        error,
        details,
        context: {
          provider,
          flow: 'callback',
          timestamp: new Date().toISOString(),
          url: window.location.href,
          state: sessionStorage.getItem('oauth_state'),
          redirectUri: sessionStorage.getItem('oauth_redirect_uri'),
          suggestions: [
            'Verify OAuth configuration',
            'Check callback URL registration',
            'Ensure state parameter handling',
            'Validate scopes and permissions'
          ]
        }
      });
    },
    oauthStateError: (provider, expectedState, receivedState) => {
      Logger.error(`${provider} state mismatch`, {
        error: 'OAuth state validation failed',
        expected: expectedState,
        received: receivedState,
        suggestion: 'This might be a CSRF attempt or the session expired'
      });
    }
  };

  // Payment Logging
  static payment = {
    attemptPayment: (amount, currency) => {
      Logger.log('Payment', `Payment attempt for ${amount} ${currency}`);
    },
    paymentSuccess: (amount, currency, transactionId) => {
      Logger.log('Payment', `Payment successful: ${amount} ${currency}`, { transactionId });
    },
    paymentFailed: (amount, currency, error) => {
      Logger.error('Payment', `Payment failed: ${amount} ${currency}`, { error });
    },
    subscriptionCreated: (planId, userId) => {
      Logger.log('Payment', `New subscription created`, { planId, userId });
    },
    subscriptionRenewed: (subscriptionId, userId) => {
      Logger.log('Payment', `Subscription renewed`, { subscriptionId, userId });
    },
    subscriptionCancelled: (subscriptionId, userId) => {
      Logger.log('Payment', `Subscription cancelled`, { subscriptionId, userId });
    }
  };
}

export default Logger; 