import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
// import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Avatar,
  Tooltip,
  Container,
  Fade,
  Slide,
  useScrollTrigger,
  Divider,
  ListItemIcon,
  Badge,
  useTheme,
  InputBase,
  alpha
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Notifications as NotificationsIcon,
  KeyboardArrowDown as ArrowDownIcon,
  Computer as ComputerIcon,
  CreditCard as BillingIcon,
  Support as SupportIcon,
  Search as SearchIcon,
  Language as LanguageIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { GAMES } from '../../config/plans';

// Temporary translation function until i18next is properly installed
const useTranslation = () => {
  return {
    t: (key) => {
      const translations = {
        'nav.plans': 'Plans',
        'nav.features': 'Features',
        'nav.about': 'About',
        'nav.contact': 'Contact',
        'nav.status': 'Status',
        'nav.login': 'Login',
        'nav.register': 'Register',
        'nav.dashboard': 'Dashboard',
        'nav.servers': 'Servers',
        'nav.billing': 'Billing',
        'nav.profile': 'Profile',
        'nav.settings': 'Settings',
        'nav.support': 'Support',
        'nav.logout': 'Logout',
        'nav.panel': 'Game Panel',
        'languages.manage': 'Manage Languages',
        'theme.light': 'Switch to light mode',
        'theme.dark': 'Switch to dark mode'
      };
      return translations[key] || key;
    },
    i18n: {
      changeLanguage: () => {}
    }
  };
};

// Hide navbar on scroll down, show on scroll up
function HideOnScroll({ children }) {
  const trigger = useScrollTrigger({
    threshold: 100,
    disableHysteresis: true
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [plansAnchorEl, setPlansAnchorEl] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [languageAnchor, setLanguageAnchor] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('en-US');
  const { t, i18n } = useTranslation();

  // Example notifications - in a real app, these would come from your backend
  const notifications = [
    { id: 1, message: 'Server backup completed', unread: true },
    { id: 2, message: 'New features available', unread: true },
    { id: 3, message: 'System maintenance scheduled', unread: false }
  ];

  const languages = [
    { 
      code: 'en-US',
      label: 'English (US)',
      flag: '🇺🇸',
      locale: 'en-US',
      direction: 'ltr'
    },
    { 
      code: 'es-ES',
      label: 'Español',
      flag: '🇪🇸',
      locale: 'es-ES',
      direction: 'ltr'
    },
    { 
      code: 'fr-FR',
      label: 'Français',
      flag: '🇫🇷',
      locale: 'fr-FR',
      direction: 'ltr'
    }
  ];

  const titleAnimation = {
    initial: { opacity: 0, y: -20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    hover: {
      scale: 1.05,
      textShadow: "0 0 8px rgb(33, 150, 243)",
      transition: {
        duration: 0.3,
        yoyo: Infinity
      }
    }
  };

  const searchBarStyle = {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  };

  const searchIconWrapper = {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const searchInputBase = {
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
        '&:focus': {
          width: '30ch',
        },
      },
    },
  };

  const iconButtonStyle = {
    mx: 0.5,
    backdropFilter: 'blur(4px)',
    borderRadius: 2,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(33, 150, 243, 0.3)',
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    handleClose();
    navigate('/auth/login');
  };

  const handleLogin = () => {
    navigate('/auth/login');
  };

  const handleRegister = () => {
    navigate('/auth/register');
  };

  const handlePlansClick = (event) => {
    // If clicking the arrow icon or if event is from menu item, show menu
    if (event?.target?.closest('.MuiSvgIcon-root') || event?.target?.closest('.MuiMenuItem-root')) {
      handlePlansMenuOpen(event);
    } else {
      // If clicking the button itself, navigate to plans
      navigate('/store');
    }
  };

  const handlePlansMenuOpen = (event) => {
    setPlansAnchorEl(event.currentTarget);
  };

  const handlePlansMenuClose = () => {
    setPlansAnchorEl(null);
  };

  const handleNotificationsOpen = (event) => {
    setNotificationsAnchor(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchor(null);
  };

  const handleLanguageChange = async (langCode) => {
    try {
      await i18n.changeLanguage(langCode);
      setCurrentLanguage(langCode);
      setLanguageAnchor(null);
      localStorage.setItem('preferredLanguage', langCode);
      
      // Update document direction if needed
      const lang = languages.find(l => l.code === langCode);
      if (lang?.direction) {
        document.dir = lang.direction;
      }
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  // Load saved language preference on mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage && languages.some(lang => lang.code === savedLanguage)) {
      setCurrentLanguage(savedLanguage);
    } else {
      // Default to browser language if supported, otherwise fallback to en-US
      const browserLang = navigator.language;
      const supportedLang = languages.find(lang => browserLang.startsWith(lang.code.split('-')[0]));
      setCurrentLanguage(supportedLang?.code || 'en-US');
    }
  }, []);

  const navItems = [
    { 
      label: t('nav.plans'),
      icon: <ArrowDownIcon />,
      onClick: handlePlansClick,
      menu: Object.values(GAMES).map(game => ({
        label: game.title,
        path: `/store/plans/${game.id}`,
        icon: game.icon
      }))
    },
    { label: t('nav.features'), path: '/features' },
    { label: t('nav.panel'), path: '/panel' },
    { label: t('nav.about'), path: '/about' },
    { label: t('nav.contact'), path: '/contact' },
    { label: t('nav.status'), path: '/status', badge: '99.9%' }
  ];

  const menuItems = [
    { label: t('nav.dashboard'), icon: <DashboardIcon />, path: '/dashboard' },
    { label: t('nav.servers'), icon: <ComputerIcon />, path: '/servers' },
    { label: t('nav.panel'), icon: <ComputerIcon />, path: '/panel' },
    { label: t('nav.billing'), icon: <BillingIcon />, path: '/billing' },
    { label: t('nav.profile'), icon: <PersonIcon />, path: '/profile' },
    { label: t('nav.settings'), icon: <SettingsIcon />, path: '/settings' },
    { label: t('nav.support'), icon: <SupportIcon />, path: '/support' }
  ];

  const baseButtonStyle = {
    fontSize: '0.95rem',
    fontWeight: 500,
    minWidth: '100px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0.5,
    backdropFilter: 'blur(4px)',
    background: 'rgba(255, 255, 255, 0.05)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 20px rgba(33, 150, 243, 0.2)',
    }
  };

  const buttonStyle = baseButtonStyle;

  const menuStyle = {
    mt: 1,
    background: 'rgba(18, 18, 18, 0.95)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: 2,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    '& .MuiMenuItem-root': {
      py: 1.5,
      px: 2,
      gap: 1.5,
      transition: 'all 0.2s ease',
      '&:hover': {
        background: 'rgba(33, 150, 243, 0.1)',
        transform: 'translateX(5px)'
      }
    }
  };

  return (
    <HideOnScroll>
      <AppBar 
        position="fixed"
        sx={{
          background: isScrolled 
            ? 'rgba(18, 18, 18, 0.95)'
            : 'transparent',
          backdropFilter: isScrolled ? 'blur(10px)' : 'none',
          boxShadow: isScrolled ? '0 4px 30px rgba(0, 0, 0, 0.1)' : 'none',
          transition: 'all 0.3s ease-in-out',
          borderBottom: isScrolled ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar 
            disableGutters 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 70,
              px: { xs: 2, md: 4 }
            }}
          >
            {/* Logo Section */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2,
              flex: { xs: 1, md: 'none' }
            }}>
              {/* Mobile Menu */}
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  onClick={handleMenu}
                  color="inherit"
                  sx={iconButtonStyle}
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              {/* Logo */}
              <motion.div
                initial="initial"
                animate="animate"
                whileHover="hover"
                variants={titleAnimation}
              >
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
                      opacity: 0.3,
                      filter: 'blur(10px)',
                      zIndex: -1,
                    }
                  }}
                >
                  JMF HOSTING
                </Typography>
              </motion.div>
            </Box>

            {/* Center Navigation */}
            <Box sx={{ 
              display: { xs: 'none', md: 'flex' }, 
              alignItems: 'center',
              gap: 2,
              mx: 4
            }}>
              {navItems.map((item) => (
                <Box key={item.label}>
                  <Button
                    color="inherit"
                    onClick={item.onClick || (() => navigate(item.path))}
                    endIcon={item.icon}
                    sx={buttonStyle}
                  >
                    {item.label}
                    {item.badge && (
                      <Box
                        component="span"
                        sx={{
                          ml: 1,
                          px: 1,
                          py: 0.25,
                          borderRadius: 1,
                          fontSize: '0.75rem',
                          background: 'rgba(76, 175, 80, 0.2)',
                          color: '#4CAF50'
                        }}
                      >
                        {item.badge}
                      </Box>
                    )}
                  </Button>
                  {item.menu && (
                    <Menu
                      anchorEl={plansAnchorEl}
                      open={Boolean(plansAnchorEl)}
                      onClose={handlePlansMenuClose}
                      TransitionComponent={Fade}
                      PaperProps={{ sx: menuStyle }}
                    >
                      {item.menu.map((menuItem) => (
                        <MenuItem 
                          key={menuItem.label}
                          onClick={() => {
                            navigate(menuItem.path);
                            handlePlansMenuClose();
                          }}
                        >
                          {menuItem.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </Box>
              ))}
            </Box>

            {/* Right Section */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              justifyContent: 'flex-end',
              flex: { xs: 1, md: 'none' }
            }}>
              {/* Search Bar */}
              <Box sx={searchBarStyle}>
                <Box sx={searchIconWrapper}>
                  <SearchIcon />
                </Box>
                <InputBase
                  placeholder="Search…"
                  sx={searchInputBase}
                />
              </Box>

              {/* Quick Actions */}
              <Box sx={{ 
                display: 'flex', 
                gap: 1,
                alignItems: 'center',
                borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
                pl: 2
              }}>
                {/* Language Selector */}
                <Tooltip title="Select language">
                  <IconButton
                    onClick={(e) => setLanguageAnchor(e.currentTarget)}
                    sx={{
                      ...iconButtonStyle,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 2,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '5px',
                        height: '5px',
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        opacity: 0.8
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <LanguageIcon />
                      <Typography variant="caption" sx={{ fontSize: '0.7rem', opacity: 0.8 }}>
                        {currentLanguage.split('-')[0].toUpperCase()}
                      </Typography>
                    </Box>
                  </IconButton>
                </Tooltip>

                {/* Theme Toggle */}
                <Tooltip title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}>
                  <IconButton
                    onClick={() => setIsDarkMode(!isDarkMode)}
                    sx={iconButtonStyle}
                  >
                    {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
                  </IconButton>
                </Tooltip>

                {isAuthenticated ? (
                  <>
                    <Tooltip title="Notifications">
                      <IconButton
                        onClick={handleNotificationsOpen}
                        sx={iconButtonStyle}
                      >
                        <Badge badgeContent={notifications.filter(n => n.unread).length} color="error">
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleMenu}
                        sx={{
                          ...iconButtonStyle,
                          border: '2px solid transparent',
                          '&:hover': {
                            border: '2px solid #2196F3',
                            transform: 'scale(1.1)',
                          }
                        }}
                      >
                        <Avatar 
                          alt={user?.name} 
                          src={user?.avatar}
                          sx={{
                            background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      onClick={handleLogin}
                      variant="outlined"
                      sx={buttonStyle}
                    >
                      {t('nav.login')}
                    </Button>
                    <Button
                      onClick={handleRegister}
                      variant="contained"
                      sx={buttonStyle}
                    >
                      {t('nav.register')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>

            {/* Menus */}
            <Menu
              anchorEl={languageAnchor}
              open={Boolean(languageAnchor)}
              onClose={() => setLanguageAnchor(null)}
              PaperProps={{ 
                sx: {
                  ...menuStyle,
                  minWidth: 200,
                  '& .MuiMenuItem-root': {
                    ...menuStyle['& .MuiMenuItem-root'],
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    py: 1.5,
                    px: 2,
                  }
                }
              }}
            >
              {languages.map((lang) => (
                <MenuItem 
                  key={lang.code}
                  onClick={() => handleLanguageChange(lang.code)}
                  sx={{
                    position: 'relative',
                    pl: 4,
                    '&:hover': {
                      background: 'rgba(33, 150, 243, 0.1)',
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  {currentLanguage === lang.code && (
                    <ListItemIcon sx={{ 
                      position: 'absolute', 
                      left: 8,
                      minWidth: 'auto',
                      color: 'primary.main' 
                    }}>
                      <CheckIcon fontSize="small" />
                    </ListItemIcon>
                  )}
                  <Box component="span" sx={{ fontSize: '1.2rem', mr: 1 }}>
                    {lang.flag}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body2">
                      {lang.label}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {lang.code}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem 
                onClick={() => {
                  window.open('/language-settings', '_blank');
                  setLanguageAnchor(null);
                }}
                sx={{ 
                  justifyContent: 'center',
                  color: 'primary.main',
                  fontSize: '0.875rem'
                }}
              >
                Manage Languages
              </MenuItem>
            </Menu>

            {/* Notifications Menu */}
            <Menu
              anchorEl={notificationsAnchor}
              open={Boolean(notificationsAnchor)}
              onClose={handleNotificationsClose}
              PaperProps={{ 
                sx: {
                  ...menuStyle,
                  minWidth: 320
                }
              }}
            >
              {notifications.map((notification) => (
                <MenuItem 
                  key={notification.id}
                  sx={{
                    borderLeft: notification.unread ? '3px solid #2196F3' : 'none'
                  }}
                >
                  {notification.message}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem sx={{ justifyContent: 'center' }}>
                <Typography variant="body2" color="primary">
                  View All Notifications
                </Typography>
              </MenuItem>
            </Menu>

            {/* User Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{ sx: menuStyle }}
            >
              {menuItems.map((item) => (
                <MenuItem 
                  key={item.label}
                  onClick={() => {
                    navigate(item.path);
                    handleClose();
                  }}
                >
                  <ListItemIcon sx={{ color: 'primary.main' }}>
                    {item.icon}
                  </ListItemIcon>
                  {item.label}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon sx={{ color: 'error.main' }}>
                  <LogoutIcon />
                </ListItemIcon>
                {t('nav.logout')}
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
};

export default Navbar; 