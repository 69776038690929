// Game server configuration with plans
export const GAMES = {
  minecraft: {
    id: 'minecraft',
    title: 'Minecraft Server Hosting',
    description: 'High-performance Minecraft servers with instant setup and DDoS protection',
    icon: '/images/games/minecraft.png',
    featuredPlanBuyButtonId: 'buy_btn_minecraft_premium',
    featuredPlanPublishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F',
    features: [
      'One-Click Modpack Installation',
      'Custom JAR Support',
      'Plugin Manager',
      'Automated Backups',
      'DDoS Protection',
      'File Manager',
      'FTP Access',
      'MySQL Database'
    ],
    plans: [
      {
        id: 'starter',
        name: 'Starter',
        price: 9.99,
        specs: {
          ram: '2GB',
          cpu: '2 vCPU',
          storage: '25GB NVMe',
          bandwidth: '1TB',
          players: '20',
          plugins: '10'
        },
        features: [
          'Daily Backups',
          'DDoS Protection',
          'Basic Plugins',
          'Community Support',
          'Custom Domain',
          'Server Console Access'
        ],
        recommended: false,
        stripeId: 'prod_RYkb8s9IsPIBhW',
        stripeBuyButtonId: 'buy_btn_1R0G9dBnDzJLjON9Ap9KP7Lw',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      },
      {
        id: 'premium',
        name: 'Premium',
        price: 24.99,
        specs: {
          ram: '8GB',
          cpu: '4 vCPU',
          storage: '100GB NVMe',
          bandwidth: '3TB',
          players: '100',
          plugins: 'Unlimited'
        },
        features: [
          'All Starter Features',
          '24/7 Premium Support',
          'Custom Modpacks',
          'Database Access',
          'Server Monitoring',
          'Full Root Access'
        ],
        recommended: true,
        stripeId: 'price_minecraft_premium',
        stripeBuyButtonId: 'buy_btn_minecraft_premium',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      },
      {
        id: 'ultimate',
        name: 'Ultimate',
        price: 39.99,
        specs: {
          ram: '16GB',
          cpu: '6 vCPU',
          storage: '200GB NVMe',
          bandwidth: '5TB',
          players: 'Unlimited',
          plugins: 'Unlimited'
        },
        features: [
          'All Premium Features',
          'Dedicated IP',
          'Enterprise Support',
          'Custom Plugin Development',
          'Performance Optimization',
          'Server Migration Service'
        ],
        recommended: false,
        stripeId: 'price_minecraft_ultimate',
        stripeBuyButtonId: 'buy_btn_minecraft_ultimate',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      },
      {
        id: 'enterprise',
        name: 'Enterprise',
        price: 79.99,
        specs: {
          ram: '32GB',
          cpu: '8 vCPU',
          storage: '500GB NVMe',
          bandwidth: '10TB',
          players: 'Unlimited',
          plugins: 'Unlimited'
        },
        features: [
          'All Ultimate Features',
          'Multi-Server Management',
          'Load Balancing',
          'Custom Development Team',
          'Priority Hardware Access',
          'Dedicated Account Manager'
        ],
        recommended: false,
        stripeId: 'price_minecraft_enterprise',
        stripeBuyButtonId: 'buy_btn_minecraft_enterprise',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      },
      {
        id: 'custom',
        name: 'Custom',
        price: 149.99,
        specs: {
          ram: '64GB',
          cpu: '12 vCPU',
          storage: '1TB NVMe',
          bandwidth: '20TB',
          players: 'Unlimited',
          plugins: 'Unlimited'
        },
        features: [
          'All Enterprise Features',
          'Custom Hardware Configuration',
          'Global Server Distribution',
          'White Label Solution',
          '24/7 Dedicated Support Team',
          'Custom SLA Agreement'
        ],
        recommended: false,
        stripeId: 'price_minecraft_custom',
        stripeBuyButtonId: 'buy_btn_minecraft_custom',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      }
    ]
  },
  unturned: {
    id: 'unturned',
    title: 'Unturned Server Hosting',
    description: 'Dedicated Unturned servers with automatic updates and backups',
    icon: '🧟',
    featuredPlanBuyButtonId: 'buy_btn_unturned_pro',
    featuredPlanPublishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F',
    features: [
      'Automatic Updates',
      'DDoS Protection', 
      'Daily Backups',
      'Community Support',
      'Server Console',
      'Mod Support'
    ],
    plans: [
      {
        id: 'basic',
        name: 'Basic',
        price: 14.99,
        specs: {
          ram: '4GB',
          cpu: '2 vCPU',
          storage: '50GB NVMe',
          bandwidth: '2TB',
          players: '10',
          mods: 'Basic'
        },
        features: [
          'Automatic Updates',
          'DDoS Protection',
          'Daily Backups', 
          'Community Support',
          'Server Console',
          'Mod Support'
        ],
        recommended: false,
        stripeId: 'price_unturned_basic',
        stripeBuyButtonId: 'buy_btn_unturned_basic',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      },
      {
        id: 'pro',
        name: 'Pro',
        price: 29.99,
        specs: {
          ram: '8GB',
          cpu: '4 vCPU',
          storage: '100GB NVMe',
          bandwidth: '4TB',
          players: '20',
          mods: 'Advanced'
        },
        features: [
          'All Basic Features',
          'Priority Support',
          'Advanced Mod Support',
          'Performance Monitoring',
          'Custom Configuration',
          'World Backups'
        ],
        recommended: true,
        stripeId: 'price_unturned_pro',
        stripeBuyButtonId: 'buy_btn_unturned_pro',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      }
    ]
  },
  rust: {
    id: 'rust',
    title: 'Rust Server Hosting',
    description: 'High-performance Rust servers with advanced moderation tools and anti-cheat',
    icon: '🔨',
    featuredPlanBuyButtonId: 'buy_btn_rust_performance',
    featuredPlanPublishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F',
    features: [
      'Anti-Cheat Protection',
      'DDoS Protection',
      'Automated Wipes',
      'Plugin Support',
      'Admin Tools',
      'Real-time Console'
    ],
    plans: [
      {
        id: 'standard',
        name: 'Standard',
        price: 19.99,
        specs: {
          ram: '8GB',
          cpu: '4 vCPU',
          storage: '75GB NVMe',
          bandwidth: '3TB',
          players: '100',
          mods: 'Basic'
        },
        features: [
          'Anti-Cheat System',
          'DDoS Protection',
          'Automated Backups',
          'Admin Tools',
          'Discord Integration',
          'Wipe Schedule'
        ],
        recommended: false,
        stripeId: 'price_rust_standard',
        stripeBuyButtonId: 'buy_btn_rust_standard',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      },
      {
        id: 'performance',
        name: 'Performance',
        price: 34.99,
        specs: {
          ram: '16GB',
          cpu: '6 vCPU',
          storage: '150GB NVMe',
          bandwidth: '5TB',
          players: '250',
          mods: 'Advanced'
        },
        features: [
          'All Standard Features',
          'Priority Support',
          'Advanced Plugin Support',
          'Custom Wipe Schedule',
          'Performance Monitoring',
          'Server Branding'
        ],
        recommended: true,
        stripeId: 'price_rust_performance',
        stripeBuyButtonId: 'buy_btn_rust_performance',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      },
      {
        id: 'elite',
        name: 'Elite',
        price: 49.99,
        specs: {
          ram: '32GB',
          cpu: '8 vCPU',
          storage: '250GB NVMe',
          bandwidth: '10TB',
          players: 'Unlimited',
          mods: 'Premium'
        },
        features: [
          'All Performance Features',
          'Dedicated IP',
          '24/7 Premium Support',
          'Custom Plugins',
          'Advanced Statistics',
          'Server Migration'
        ],
        recommended: false,
        stripeId: 'price_rust_elite',
        stripeBuyButtonId: 'buy_btn_rust_elite',
        publishableKey: 'pk_live_51Qf3D6BnDzJLjON9eiCenJisP7hdqqvfsbmij4dW7YuN8IETBTlEbkCGGShN7zpDJn0GrbqMoZomK4WIWFxZAZj900D6T8GG1F'
      }
    ]
  }
};

// Helper functions
export const getGameById = (gameId) => {
  return GAMES[gameId] || null;
};

export const getGamePlans = (gameId) => {
  const game = GAMES[gameId];
  return game ? game.plans : [];
};

export const isValidGameId = (gameId) => {
  return Object.keys(GAMES).includes(gameId);
};

export const getStripePriceId = (gameId, planId) => {
  const game = GAMES[gameId];
  if (!game) return null;
  
  const plan = game.plans.find(p => p.id === planId);
  return plan ? plan.stripeId : null;
};

export const getStripeBuyButtonId = (gameId, planId) => {
  const game = GAMES[gameId];
  if (!game) return null;
  
  const plan = game.plans.find(p => p.id === planId);
  return plan ? plan.stripeBuyButtonId : null;
};

export const getStripePublishableKey = (gameId, planId) => {
  const game = GAMES[gameId];
  if (!game) return null;
  
  const plan = game.plans.find(p => p.id === planId);
  return plan ? plan.publishableKey : null;
};

export const getFeaturedPlanPublishableKey = (gameId) => {
  const game = GAMES[gameId];
  return game ? game.featuredPlanPublishableKey : null;
};