import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  useTheme,
  Stack,
  Button,
} from '@mui/material';
import {
  Memory,
  Storage,
  Speed,
  CloudQueue,
  Security,
  SupportAgent,
  Check,
  Close,
  PieChart
} from '@mui/icons-material';
import '../../styles/Store.css';
import StripeBuyButton from '../../components/payment/StripeBuyButton';
import { useAuth } from '../../context/AuthContext';

const games = [
  {
    id: 'minecraft',
    name: 'Minecraft',
    logo: '/images/games/minecraft/logo.png',
    background: '/images/games/minecraft/background.jpg',
    description: 'Host your Minecraft server with advanced features and powerful hardware.',
    startingPrice: 3.99,
    currentPrice: 4.99,
    features: [
      { name: 'One-Click Installation', icon: <Speed className="feature-icon" />, included: true },
      { name: 'Automatic Backups', icon: <Storage className="feature-icon" />, included: true },
      { name: 'DDoS Protection', icon: <Security className="feature-icon" />, included: true },
      { name: 'Plugin Support', icon: <Memory className="feature-icon" />, included: true },
      { name: 'Custom Domain', icon: <CloudQueue className="feature-icon" />, included: true },
      { name: '24/7 Support', icon: <SupportAgent className="feature-icon" />, included: true }
    ],
    popularity: 95,
    avgPrice: 7.99
  },
  {
    id: 'unturned',
    name: 'Unturned',
    logo: '/images/games/unturned/logo.png',
    background: '/images/games/unturned/background.jpg',
    description: 'Run your Unturned server with high performance and DDoS protection.',
    startingPrice: 3.99,
    currentPrice: 2.99,
    features: [
      { name: 'Workshop Mod Support', icon: <Check className="feature-icon" />, included: true },
      { name: 'Custom Config Files', icon: <Check className="feature-icon" />, included: true },
      { name: 'Anti-Cheat Protection', icon: <Security className="feature-icon" />, included: true },
      { name: 'Up to 50 Players', icon: <Memory className="feature-icon" />, included: true },
      { name: 'Instant Setup', icon: <Speed className="feature-icon" />, included: true },
      { name: '24/7 Customer Support', icon: <SupportAgent className="feature-icon" />, included: true }
    ],
    popularity: 85,
    avgPrice: 2.99
  },
  {
    id: 'rust',
    name: 'Rust',
    logo: '/images/games/rust/logo.png',
    background: '/images/games/rust/background.jpg',
    description: 'Deploy your Rust server with premium hardware and low latency.',
    startingPrice: 14.99,
    currentPrice: 12.99,
    features: [
      { name: 'Premium Hardware', icon: <Check className="feature-icon" />, included: true },
      { name: 'Anti-Hack Protection', icon: <Security className="feature-icon" />, included: true },
      { name: 'Plugin Support', icon: <Memory className="feature-icon" />, included: true },
      { name: 'Up to 200 Players', icon: <Check className="feature-icon" />, included: true },
      { name: 'Custom Mods', icon: <Check className="feature-icon" />, included: true },
      { name: 'Priority Support', icon: <SupportAgent className="feature-icon" />, included: true }
    ],
    popularity: 90,
    avgPrice: 12.99
  }
];

const StorePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { isAuthenticated } = useAuth();

  return (
    <Box className="store-container">
      <Box className="store-hero-section">
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            className="hero-title"
            sx={{
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
              mb: 4,
              color: '#4caf50',
            }}
          >
            Choose your game and get started with professional hosting
          </Typography>
        </Container>
      </Box>

      <Box 
        className="features-section"
        sx={{ 
          py: 4,
          position: 'relative',
          backdropFilter: 'blur(5px)',
        }}
      >
        <Container maxWidth={false} sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
          <Grid container spacing={4}>
            {games.map((game) => (
              <Grid item xs={12} sm={6} md={4} key={game.id}>
                <Card className="store-card" sx={{ padding: '16px', transition: '0.3s', '&:hover': { boxShadow: '0 0 20px rgba(76, 175, 80, 0.5)', transform: 'scale(1.05)' } }}>
                  <Box className="game-background" sx={{ backgroundImage: `url(${game.background})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '200px', borderRadius: '8px 8px 0 0', position: 'relative' }}>
                    <img src={game.logo} alt={game.name} className="game-icon" />
                  </Box>
                  <CardContent sx={{ p: 3 }}>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="h5" sx={{ color: theme.palette.primary.main, mb: 1, fontSize: '1.5rem' }}>
                        {game.name}
                      </Typography>
                      <img src={game.logo} alt={game.name} className="game-icon" style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '8px' }} />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 2 }}>
                      <Typography variant="h4" sx={{ color: '#4CAF50', fontWeight: 'bold', fontSize: '2rem' }}>
                        ${game.currentPrice}
                      </Typography>
                      <Typography sx={{ ml: 1, color: 'text.secondary' }}>/mo</Typography>
                      <Typography 
                        sx={{ 
                          ml: 2,
                          color: 'error.main',
                          textDecoration: 'line-through',
                          opacity: 0.7,
                        }}
                      >
                        ${game.startingPrice}/mo
                      </Typography>
                    </Box>
                    <Stack spacing={1.5} sx={{ mb: 3 }}>
                      {game.features.map((feature, index) => (
                        <Stack 
                          key={index} 
                          direction="row" 
                          alignItems="center" 
                          spacing={1}
                          sx={{
                            p: 1,
                            borderRadius: 1,
                            background: feature.included ? 'rgba(76, 175, 80, 0.1)' : 'rgba(244, 67, 54, 0.1)',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              transform: 'translateX(5px)',
                              background: feature.included ? 'rgba(76, 175, 80, 0.2)' : 'rgba(244, 67, 54, 0.2)',
                            }
                          }}
                        >
                          {feature.icon}
                          <Typography sx={{ flex: 1 }}>{feature.name}</Typography>
                          {feature.included ? (
                            <Check sx={{ color: 'success.main' }} />
                          ) : (
                            <Close sx={{ color: 'error.main' }} />
                          )}
                        </Stack>
                      ))}
                    </Stack>

                    {/* Circle Graph for Popularity and Avg Price */}
                    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                      <Box sx={{ position: 'relative', width: '80px', height: '80px' }}>
                        <PieChart sx={{ position: 'absolute', top: 0, left: 0, color: 'success.main' }} />
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            transform: 'translate(-50%, -50%)', 
                            color: 'white' 
                          }}
                        >
                          {game.popularity}%
                        </Typography>
                      </Box>
                      <Box sx={{ position: 'relative', width: '80px', height: '80px' }}>
                        <PieChart sx={{ position: 'absolute', top: 0, left: 0, color: 'success.main' }} />
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            transform: 'translate(-50%, -50%)', 
                            color: 'white' 
                          }}
                        >
                          {((game.avgPrice / game.startingPrice) * 100).toFixed(0)}%
                        </Typography>
                      </Box>
                    </Stack>

                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => navigate(`/store/plans/${game.id}`)}
                      sx={{
                        py: 1.5,
                        background: 'linear-gradient(45deg, #4CAF50 30%, #45a049 90%)',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.02)',
                          boxShadow: '0 6px 20px rgba(76, 175, 80, 0.4)'
                        }
                      }}
                    >
                      View Plans
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default StorePage; 