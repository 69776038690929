/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Grid, 
  Typography, 
  Button, 
  Box,
  useTheme,
  Modal,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { 
  GAMES, 
  getGameById, 
  getGamePlans, 
  getStripePriceId, 
  STRIPE_PUBLISHABLE_KEY 
} from '../../config/plans';
import api from '../../services/api';
import config from '../../config';
import StripeBuyButton from '../../components/payment/StripeBuyButton';

const GamePlans = () => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { gameId } = useParams();
  const location = useLocation();
  const [error, setError] = useState(null);

  const game = getGameById(gameId) || GAMES.minecraft;
  const plans = game.plans;

  // Redirect to login if not authenticated
  const handleAuthCheck = () => {
    if (!isAuthenticated) {
      navigate('/auth/login', { state: { from: location.pathname } });
      return false;
    }
    return true;
  };

  const PlanCard = ({ plan }) => (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(255, 255, 255, 0.05)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.4)'
        }
      }}
    >
      <CardContent sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {plan.name}
        </Typography>
        <Typography variant="h4" color="primary" gutterBottom>
          ${plan.price}/mo
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          Specifications
        </Typography>
        <List dense>
          {Object.entries(plan.specs).map(([key, value]) => (
            <ListItem key={key}>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText 
                primary={`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`} 
              />
            </ListItem>
          ))}
        </List>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Features
        </Typography>
        <List dense>
          {plan.features.map((feature, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>

        {isAuthenticated ? (
          <StripeBuyButton 
            buyButtonId={plan.stripeBuyButtonId} 
            publishableKey={plan.publishableKey}
          />
        ) : (
          <Button
            variant="contained"
            fullWidth
            onClick={handleAuthCheck}
            sx={{ mt: 3 }}
          >
            Login to Purchase
          </Button>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth={false} sx={{ py: 8, px: { xs: 2, sm: 3, md: 4 } }}>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mb: 6, textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          {game.title}
        </Typography>
        <Typography variant="h5" color="textSecondary" paragraph>
          {game.description}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap', mb: 4 }}>
          {game.features.map((feature, index) => (
            <Chip
              key={index}
              label={feature}
              color="primary"
              variant="outlined"
              sx={{ m: 0.5 }}
            />
          ))}
        </Box>
      </Box>

      <Grid container spacing={4}>
        {plans.map((plan) => (
          <Grid item xs={12} md={6} lg={4} key={plan.id}>
            <PlanCard plan={plan} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default GamePlans; 