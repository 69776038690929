import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Alert, Paper, Button } from '@mui/material';
import StripeBuyButton from './StripeBuyButton';
import stripeService from '../../services/stripeService';
import { useAuth } from '../../context/AuthContext';

/**
 * Example component showing how to use the StripeBuyButton with custom attributes
 */
const StripeBuyButtonExample = ({ 
  buyButtonId, 
  publishableKey,
  title = 'Purchase with Stripe',
  description = 'Click the button below to complete your purchase securely with Stripe.'
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customerSession, setCustomerSession] = useState(null);
  const [useCustomerSession, setUseCustomerSession] = useState(false);

  // Fetch customer session when component mounts
  useEffect(() => {
    if (useCustomerSession && user) {
      fetchCustomerSession();
    }
  }, [useCustomerSession, user]);

  // Function to fetch a customer session
  const fetchCustomerSession = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // First get the customer ID
      const customerId = await stripeService.getCustomerId();
      
      if (customerId) {
        // Then create a customer session
        const session = await stripeService.createCustomerSession(customerId);
        setCustomerSession(session);
      } else {
        setError('No Stripe customer found. Please complete a purchase first.');
      }
    } catch (err) {
      console.error('Error fetching customer session:', err);
      setError('Failed to create customer session. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 3, mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      
      <Typography variant="body1" paragraph>
        {description}
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      <Box sx={{ mb: 3 }}>
        <Button 
          variant="outlined" 
          color={useCustomerSession ? "primary" : "inherit"}
          onClick={() => setUseCustomerSession(!useCustomerSession)}
          sx={{ mb: 2 }}
        >
          {useCustomerSession ? "Using Customer Session" : "Use Customer Session"}
        </Button>
        
        {useCustomerSession && (
          <Typography variant="body2" color="text.secondary" paragraph>
            Using customer session allows you to pass an existing Customer object to the checkout.
          </Typography>
        )}
      </Box>
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <StripeBuyButton 
          buyButtonId={buyButtonId}
          publishableKey={publishableKey}
          clientReferenceId={user?.id}
          customerEmail={user?.email}
          customerSessionClientSecret={useCustomerSession ? customerSession?.clientSecret : undefined}
        />
      )}
      
      <Box sx={{ mt: 3 }}>
        <Typography variant="subtitle2" gutterBottom>
          Custom Attributes Used:
        </Typography>
        <Typography variant="body2">
          • <strong>client-reference-id:</strong> {user?.id || 'Not available'}
        </Typography>
        <Typography variant="body2">
          • <strong>customer-email:</strong> {user?.email || 'Not available'}
        </Typography>
        <Typography variant="body2">
          • <strong>customer-session-client-secret:</strong> {useCustomerSession ? (customerSession?.clientSecret ? 'Provided' : 'Not available') : 'Not used'}
        </Typography>
      </Box>
    </Paper>
  );
};

export default StripeBuyButtonExample; 