import axios from 'axios';
import api from './api';

const stripeService = {
  async fetchPrices() {
    try {
      const response = await api.get('/stripe/prices');
      return response.data;
    } catch (error) {
      console.error('Error fetching prices:', error);
      throw error;
    }
  },

  async createCheckoutSession(priceId) {
    try {
      const response = await api.post('/stripe/create-checkout-session', {
        priceId
      });
      return response.data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  },
  
  /**
   * Creates a customer session for use with the Stripe Buy Button
   * @param {string} customerId - The Stripe customer ID
   * @returns {Promise<Object>} - The customer session data with client_secret
   */
  async createCustomerSession(customerId) {
    try {
      const response = await api.post('/stripe/customer-session', { customerId });
      return response.data;
    } catch (error) {
      console.error('Error creating customer session:', error);
      throw error;
    }
  },
  
  /**
   * Gets the current user's Stripe customer ID
   * @returns {Promise<string>} - The Stripe customer ID
   */
  async getCustomerId() {
    try {
      const response = await api.get('/stripe/customer');
      return response.data.customerId;
    } catch (error) {
      console.error('Error getting customer ID:', error);
      throw error;
    }
  }
};

export default stripeService; 