import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Google as GoogleIcon, GitHub as GitHubIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { Notifications } from '../../utils/notifications';
import Logger from '../../utils/logger';

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, handleOAuthLogin, isAuthenticated } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
    rememberMe: false
  });
  const [loading, setLoading] = useState(false);

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  // Show success message from location state
  useEffect(() => {
    if (location.state?.message) {
      Notifications.custom('Success', location.state.message, 'success');
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    Logger.auth.loginAttempt(formData.identifier);

    try {
      await login(formData);
      Logger.auth.loginSuccess(formData.identifier);
      Notifications.authSuccess();
    } catch (err) {
      Logger.auth.loginFailed(formData.identifier, err);
      Notifications.authError({ message: err.message || 'Login failed. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleOAuth = async (provider) => {
    try {
      setLoading(true);
      Logger.auth.oauthStart(provider);
      await handleOAuthLogin(provider);
      Logger.auth.oauthSuccess(provider, 'user');
    } catch (err) {
      Logger.auth.oauthFailed(provider, err);
      Notifications.authError({ message: `${provider} login failed: ${err.message}` });
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const newValue = name === 'rememberMe' ? checked : value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000000',
        pt: '64px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at top left, rgba(33, 150, 243, 0.1), transparent 40%), radial-gradient(circle at top right, rgba(255, 64, 129, 0.1), transparent 40%)',
          pointerEvents: 'none',
        }
      }}
    >
      <Container 
        maxWidth="xs" 
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 3,
          py: 4,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '400px',
            textAlign: 'center',
            mb: 4
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '2.5rem', sm: '3rem' },
              fontWeight: 800,
              letterSpacing: '-0.5px',
              mb: 1,
              background: 'linear-gradient(45deg, #2196F3, #FF4081)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: '0 0 30px rgba(33, 150, 243, 0.3)',
            }}
          >
            Welcome Back
          </Typography>
          <Typography 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.9)',
              fontSize: '1.1rem',
              mb: 4,
              fontWeight: 400,
            }}
          >
            Sign in to continue to JMF Hosting
          </Typography>
        </Box>

        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            maxWidth: '400px',
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Button
              fullWidth
              startIcon={<GoogleIcon />}
              onClick={() => handleOAuth('google')}
              disabled={loading}
              sx={{
                mb: 2,
                py: 1.5,
                px: 2,
                color: '#fff',
                fontSize: '1rem',
                fontWeight: 500,
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '8px',
                background: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderColor: '#fff',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)'
                }
              }}
            >
              Continue with Google
            </Button>
            <Button
              fullWidth
              startIcon={<GitHubIcon />}
              onClick={() => handleOAuth('github')}
              disabled={loading}
              sx={{
                py: 1.5,
                px: 2,
                color: '#fff',
                fontSize: '1rem',
                fontWeight: 500,
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '8px',
                background: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderColor: '#fff',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)'
                }
              }}
            >
              Continue with GitHub
            </Button>
          </Box>

          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 3,
              '&::before, &::after': {
                content: '""',
                flex: 1,
                borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
              }
            }}
          >
            <Typography 
              sx={{ 
                px: 2, 
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '0.9rem',
                fontWeight: 500,
              }}
            >
              or sign in with email
            </Typography>
          </Box>

          <TextField
            fullWidth
            label="Email or Username"
            name="identifier"
            type="text"
            value={formData.identifier}
            onChange={handleChange}
            disabled={loading}
            required
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                fontSize: '1.1rem',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                borderRadius: '8px',
                height: '56px',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2196F3',
                  borderWidth: '2px',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '1.1rem',
                fontWeight: 500,
                transform: 'translate(14px, 16px) scale(1)',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                  color: '#2196F3',
                },
              },
            }}
          />

          <TextField
            fullWidth
            label="Password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            disabled={loading}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                fontSize: '1.1rem',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                borderRadius: '8px',
                height: '56px',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2196F3',
                  borderWidth: '2px',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '1.1rem',
                fontWeight: 500,
                transform: 'translate(14px, 16px) scale(1)',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                  color: '#2196F3',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="rememberMe"
                checked={formData.rememberMe}
                onChange={handleChange}
                color="primary"
                disabled={loading}
              />
            }
            label="Remember me"
            sx={{ mt: 1, color: 'rgba(255, 255, 255, 0.7)' }}
          />

          <Button
            type="submit"
            fullWidth
            disabled={loading}
            sx={{
              mt: 3,
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 600,
              background: 'linear-gradient(45deg, #2196F3, #FF4081)',
              color: '#fff',
              borderRadius: '8px',
              '&:hover': {
                background: 'linear-gradient(45deg, #1976D2, #D81B60)',
              }
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
          </Button>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography sx={{ fontSize: '0.9rem', color: 'rgba(255, 255, 255, 0.7)' }}>
              Don't have an account?{' '}
              <Link 
                component={RouterLink} 
                to="/auth/register" 
                sx={{ 
                  color: '#2196F3',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Sign up here
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
