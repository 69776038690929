import React, { useState } from 'react';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Button,
  Typography,
  Chip,
  Box,
  useTheme
} from '@mui/material';
import {
  PlayArrow as StartIcon,
  Stop as StopIcon,
  Refresh as RestartIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import Navbar from '../../components/layout/Navbar';

const Servers = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Mock data - replace with real API call
  const servers = [
    {
      id: 1,
      name: 'Survival Server',
      owner: 'user@example.com',
      status: 'running',
      type: 'minecraft',
      plan: 'premium',
      ram: '16GB',
      cpu: '4 cores',
      uptime: '99.9%'
    },
    // Add more mock servers...
  ];

  return (
    <>
      <Navbar />
      <Box sx={{ 
        flexGrow: 1, 
        width: '100%', 
        pt: 8,
        pb: 4,
        backgroundColor: 'rgba(0,0,0,0.9)'
      }}>
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant="h4">
              Server Management
            </Typography>
            <Button variant="contained" color="primary">
              Add Server
            </Button>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Resources</TableCell>
                  <TableCell>Uptime</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servers.map((server) => (
                  <TableRow key={server.id}>
                    <TableCell>{server.name}</TableCell>
                    <TableCell>{server.owner}</TableCell>
                    <TableCell>
                      <Chip 
                        label={server.status}
                        color={server.status === 'running' ? 'success' : 'error'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{server.type}</TableCell>
                    <TableCell>{server.plan}</TableCell>
                    <TableCell>
                      {server.ram} / {server.cpu}
                    </TableCell>
                    <TableCell>{server.uptime}</TableCell>
                    <TableCell>
                      <IconButton size="small" color="success">
                        <StartIcon />
                      </IconButton>
                      <IconButton size="small" color="error">
                        <StopIcon />
                      </IconButton>
                      <IconButton size="small">
                        <RestartIcon />
                      </IconButton>
                      <IconButton size="small">
                        <SettingsIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={servers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </TableContainer>
        </Container>
      </Box>
    </>
  );
};

export default Servers; 