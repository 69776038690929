import React, { useState } from 'react';
import { Container, Typography, Paper, Box, IconButton, Collapse } from '@mui/material';
import { 
  ExpandMore, ExpandLess, Description, Payment, Gavel, Assignment, 
  Storage, ExitToApp, Update, Info, Person, Timer, Memory, Security, 
  Block, CalendarToday, Money, Warning, Rule, Balance, Shield, Visibility,
  Feedback, Folder, Speed, CalculateOutlined, Headset, Backup, Router, 
  TrendingUp, Monitor, Build, Logout, Delete, Receipt, Restore,
  Check, Notifications, Edit, Key, Autorenew, PauseCircle, Download, Nature
} from '@mui/icons-material';
import termsContent from '../../content/terms.json';
import '../../styles/legal.css';
console.log('Terms content loaded:', termsContent);

const bulletIcons = {
  info: <Info />,
  person: <Person />,
  timer: <Timer />,
  memory: <Memory />,
  security: <Security />,
  key: <Key />,
  block: <Block />,
  payment: <Payment />,
  calendar: <CalendarToday />,
  money: <Money />,
  warning: <Warning />,
  verified: <Check />,
  rule: <Rule />,
  speed: <Speed />,
  balance: <Balance />,
  shield: <Shield />,
  visibility: <Visibility />,
  gavel: <Gavel />,
  feedback: <Feedback />,
  folder: <Folder />,
  calculate: <CalculateOutlined />,
  assignment: <Assignment />,
  headset: <Headset />,
  backup: <Backup />,
  router: <Router />,
  trending_up: <TrendingUp />,
  monitor: <Monitor />,
  build: <Build />,
  logout: <Logout />,
  delete: <Delete />,
  receipt: <Receipt />,
  restore: <Restore />,
  check: <Check />,
  notifications: <Notifications />,
  edit: <Edit />,
  storage: <Storage />,
  date_range: <CalendarToday />,
  autorenew: <Autorenew />,
  pause: <PauseCircle />,
  download: <Download />,
  eco: <Nature />
};

const sectionIcons = {
  'Service Agreement': <Description />,
  'Payment Terms': <Payment />,
  'Acceptable Use Policy': <Gavel />,
  'Service Level Agreement': <Assignment />,
  'Infrastructure and Resources': <Storage />,
  'Term and Termination': <ExitToApp />,
  'Amendments and Updates': <Update />
};

const keyPoints = [
  { icon: <Info sx={{ fontSize: 20 }} />, text: 'These Terms of Service constitute a legally binding agreement between you and JMF Hosting.' },
  { icon: <Person sx={{ fontSize: 20 }} />, text: 'By accessing or using our services, you confirm that you are at least 18 years of age or have obtained parental consent, and that you have the legal capacity to enter into this agreement.' },
  { icon: <Timer sx={{ fontSize: 20 }} />, text: 'Please read these terms carefully as they govern your use of our game server hosting services.' }
];

const TermsIntroduction = () => (
  <Paper elevation={3} className="legal-introduction">
    <Typography variant="body1" paragraph>
      Welcome to JMF Hosting. By using our services, you agree to be bound by these Terms of Service. 
      Please read these terms carefully as they govern your use of our game server hosting services.
    </Typography>
    <Typography variant="body1" paragraph>
      These Terms of Service constitute a legally binding agreement between you and JMF Hosting. 
      If you do not agree with any part of these terms, you must not use our services.
    </Typography>
    <Typography variant="body1" paragraph>
      By accessing or using our services, you confirm that you are at least 18 years of age or have 
      obtained parental consent, and that you have the legal capacity to enter into this agreement.
    </Typography>
    
    <div className="key-points">
      <Typography className="key-points-title">
        <Info sx={{ fontSize: 20 }} /> Key Points
      </Typography>
      {keyPoints.map((point, index) => (
        <div key={index} className="key-point">
          <div className="key-point-icon">
            {point.icon}
          </div>
          <Typography variant="body2">{point.text}</Typography>
        </div>
      ))}
    </div>

    <div className="date-info">
      <div className="date-item">
        <span className="date-label">Last Updated</span>
        <span className="date-value">{formatDate(termsContent.lastUpdated)}</span>
      </div>
      <div className="date-item">
        <span className="date-label">Effective Date</span>
        <span className="date-value">{formatDate(termsContent.effectiveDate)}</span>
      </div>
    </div>
  </Paper>
);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short'
  });
};

const BulletPoint = ({ title, icon, description, lastModified }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="legal-bullet-point">
      <div className="bullet-header" onClick={() => setExpanded(!expanded)}>
        <div className="bullet-title-wrapper">
          {icon && bulletIcons[icon.toLowerCase()] && (
            <div className="bullet-icon">
              {bulletIcons[icon.toLowerCase()]}
            </div>
          )}
          <div className="bullet-content">
            <Typography variant="body1" component="div" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Last modified: {formatDate(lastModified)}
            </Typography>
          </div>
        </div>
        <IconButton size="small" className={`expand-button bullet ${expanded ? 'expanded' : ''}`}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
      <Collapse in={expanded} timeout={300}>
        <Typography variant="body2" className="legal-description">
          {description}
        </Typography>
      </Collapse>
    </div>
  );
};

const LegalSection = ({ section }) => {
  const [expanded, setExpanded] = useState(false);
  const [expandedSubsections, setExpandedSubsections] = useState({});

  console.log('Section:', section); // Debug log

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const handleSubsectionClick = (subsectionId) => {
    setExpandedSubsections(prev => ({
      ...prev,
      [subsectionId]: !prev[subsectionId]
    }));
  };

  const renderContent = () => {
    console.log('Rendering content for section:', section.title);
    console.log('Subsections:', section.subsections);

    if (!section.subsections) {
      return null;
    }

    return (
      <div className="legal-section-content">
        {section.subsections.map((subsection) => (
          console.log('Rendering subsection:', subsection.title) ||
          <div 
            key={subsection.id}
            className="legal-subsection"
          >
            <div className="subsection-header" onClick={() => handleSubsectionClick(subsection.id)}>
              <div className="subsection-title-wrapper">
                <div className="subsection-icon">
                  {bulletIcons[subsection.icon?.toLowerCase()]}
                </div>
                <Typography variant="h6">
                  {subsection.title}
                </Typography>
              </div>
              <IconButton>
                {expandedSubsections[subsection.id] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>

            <Collapse in={expandedSubsections[subsection.id]}>
              <Box sx={{ p: 2 }}>
                {subsection.bulletPoints.map((bullet) => (
                  <BulletPoint
                    key={bullet.title}
                    title={bullet.title}
                    icon={bullet.icon}
                    description={bullet.content}
                    lastModified={bullet.lastModified}
                  />
                ))}
              </Box>
            </Collapse>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Paper elevation={3} className={`legal-section ${expanded ? 'expanded' : ''}`}>
      <div className="legal-section-header" onClick={handleClick}>
        <div className="section-icon">
          {sectionIcons[section.title]}
        </div>
        <Typography variant="h4">
          {section.title}
        </Typography>
        <IconButton>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>

      <Collapse in={expanded}>
        {renderContent()}
      </Collapse>
    </Paper>
  );
};

const Terms = () => {
  console.log('Terms content:', termsContent); // Debug log

  if (!termsContent || !termsContent.sections) {
    return (
      <div className="legal-container">
        <Container maxWidth="lg">
          <Box className="legal-header">
            <Typography variant="h2" gutterBottom className="legal-title">
              Terms of Service
            </Typography>
            <Typography variant="body1">
              Loading terms of service...
            </Typography>
          </Box>
        </Container>
      </div>
    );
  }

  return (
    <div className="legal-container">
      <Container maxWidth="lg">
        <div className="legal-content-wrapper">
          <Box className="legal-header">
            <Typography variant="h2" gutterBottom className="legal-title">
              Terms of Service
            </Typography>
            <Box className="legal-metadata">
              <Typography variant="subtitle1" gutterBottom>
                Version {termsContent.version}
              </Typography>
              <Typography variant="subtitle2">
                Effective Date: {formatDate(termsContent.effectiveDate)}
              </Typography>
              <Typography variant="subtitle2">
                Last Updated: {formatDate(termsContent.lastUpdated)}
              </Typography>
            </Box>
          </Box>

          <TermsIntroduction />

          {/* Debug log */}
          {console.log('Rendering sections:', termsContent.sections)}
          
          {termsContent.sections.map((section) => (
            console.log('Rendering section:', section.title) ||
            <LegalSection key={section.id} section={section} />
          ))}

          <Paper elevation={3} className="legal-contact">
            <Typography variant="h4" className="legal-section-title">
              <Headset sx={{ fontSize: 28 }} /> Contact Us
            </Typography>
            <Typography variant="body1">
              For questions about these Terms of Service, please contact our Support Staff at{' '}
              <a href="mailto:support@jmfhosting.com">
                support@jmfhosting.com
              </a>
            </Typography>
          </Paper>
        </div>
      </Container>
    </div>
  );
};

export default Terms; 