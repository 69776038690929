import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Paper, 
  Typography, 
  Button, 
  CircularProgress, 
  Alert,
  Box
} from '@mui/material';
import { verifyEmail, resendVerification } from '../../api/auth';

const EmailVerification = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [email, setEmail] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      
      if (!token) {
        setLoading(false);
        setError('No verification token found');
        return;
      }

      try {
        const response = await verifyEmail(token);
        setSuccess(response.message || 'Email verified successfully');
        setEmail(response.email);
        // Auto-redirect after successful verification
        setTimeout(() => navigate('/auth/login'), 5000);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to verify email');
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [location, navigate]);

  const handleResendVerification = async () => {
    if (!email) {
      setError('No email available for resending verification');
      return;
    }

    try {
      setLoading(true);
      const response = await resendVerification(email);
      setSuccess(response.message || 'Verification email sent successfully');
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to resend verification email');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000000',
        pt: '64px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at top left, rgba(33, 150, 243, 0.1), transparent 40%), radial-gradient(circle at top right, rgba(255, 64, 129, 0.1), transparent 40%)',
          pointerEvents: 'none',
        }
      }}
    >
      <Container maxWidth="sm">
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4, 
            background: 'rgba(45, 45, 45, 0.9)',
            backdropFilter: 'blur(10px)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.1)'
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '2rem', sm: '2.5rem' },
                fontWeight: 700,
                background: 'linear-gradient(45deg, #2196F3, #FF4081)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                mb: 2
              }}
            >
              {loading ? 'Verifying Email...' : success ? 'Email Verified!' : 'Verification Failed'}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
                maxWidth: '400px',
                margin: '0 auto'
              }}
            >
              {loading 
                ? 'Please wait while we verify your email address...'
                : success 
                  ? 'Your email has been verified successfully. You will be redirected to the login page shortly.'
                  : 'We were unable to verify your email address. Please try again or request a new verification link.'
              }
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {success}
            </Alert>
          )}

          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress size={60} sx={{ color: '#2196F3' }} />
            </Box>
          )}

          {!loading && !success && (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography
                variant="body2"
                sx={{ color: 'rgba(255, 255, 255, 0.5)', mb: 2 }}
              >
                If you're having trouble with the verification link, you can also:
              </Typography>
              <Button
                onClick={handleResendVerification}
                disabled={!email || loading}
                sx={{
                  color: '#2196F3',
                  mb: 2,
                  '&:hover': {
                    backgroundColor: 'rgba(33, 150, 243, 0.1)',
                  }
                }}
              >
                Resend Verification Email
              </Button>
              <Button
                onClick={() => navigate('/auth/verify-email')}
                sx={{
                  color: '#FF4081',
                  display: 'block',
                  mx: 'auto',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 64, 129, 0.1)',
                  }
                }}
              >
                Enter Verification Code Manually
              </Button>
            </Box>
          )}

          {success && (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Button
                onClick={() => navigate('/auth/login')}
                variant="contained"
                sx={{
                  py: 1.5,
                  px: 4,
                  fontSize: '1rem',
                  fontWeight: 600,
                  background: 'linear-gradient(45deg, #2196F3, #FF4081)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #1976D2, #D81B60)',
                  }
                }}
              >
                Go to Login
              </Button>
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default EmailVerification; 