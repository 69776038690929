import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  IconButton,
  InputAdornment,
  LinearProgress
} from '@mui/material';
import { Google as GoogleIcon, GitHub as GitHubIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { Notifications } from '../../utils/notifications';
import Logger from '../../utils/logger';

const RegisterPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleOAuthLogin, isAuthenticated } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    if (name === 'password') {
      checkPasswordStrength(value);
    }
  };

  const checkPasswordStrength = (password) => {
    const criteria = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
    
    setPasswordCriteria(criteria);
    const strength = Object.values(criteria).filter(Boolean).length * 20;
    setPasswordStrength(strength);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    Logger.auth.registerAttempt(formData.email);

    if (formData.password !== formData.confirmPassword) {
      Logger.auth.registerFailed(formData.email, 'Passwords do not match');
      Notifications.custom('Registration Error', 'Passwords do not match', 'error');
      setLoading(false);
      return;
    }

    if (passwordStrength < 60) {
      Logger.auth.registerFailed(formData.email, 'Password strength insufficient');
      Notifications.custom('Registration Error', 'Please use a stronger password', 'error');
      setLoading(false);
      return;
    }

    try {
      const registrationData = {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        firstName: formData.firstName.trim() || null,
        lastName: formData.lastName.trim() || null
      };
      
      const response = await register(registrationData);
      
      if (response.error) {
        Logger.auth.registerFailed(formData.email, response.error);
        Notifications.custom('Registration Error', response.error, 'error');
      } else {
        Logger.auth.registerSuccess(formData.email);
        Notifications.custom(
          'Registration Successful',
          'Please check your email for verification instructions. You can either click the link in the email or enter the verification code manually.',
          'success',
          null
        );
        
        // Navigate to verify email page with appropriate state
        const navigationState = { 
          email: formData.email, 
          message: 'Registration successful! You can enter the verification code from your email below, or click the link in your email to verify automatically.'
        };
        
        // If the API returns a verification code directly, include it in the state
        if (response.verificationCode) {
          navigationState.code = response.verificationCode;
          navigationState.message = 'Registration successful! Verifying your email automatically...';
        }
        
        navigate('/auth/verify-email', { state: navigationState });
      }
    } catch (err) {
      Logger.auth.registerFailed(formData.email, err);
      Notifications.custom('Registration Error', err.message || 'Registration failed', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleOAuth = async (provider) => {
    try {
      setLoading(true);
      Logger.auth.oauthStart(provider);
      await handleOAuthLogin(provider);
      Logger.auth.oauthSuccess(provider, 'user');
    } catch (err) {
      Logger.auth.oauthFailed(provider, err);
      Notifications.custom(
        'Authentication Error',
        `${provider} registration failed: ${err.message}`,
        'error'
      );
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000000',
        pt: '64px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at top left, rgba(33, 150, 243, 0.1), transparent 40%), radial-gradient(circle at top right, rgba(255, 64, 129, 0.1), transparent 40%)',
          pointerEvents: 'none',
        }
      }}
    >
      <Container 
        maxWidth="xs" 
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 3,
          py: 4,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '400px',
            textAlign: 'center',
            mb: 4
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '2.5rem', sm: '3rem' },
              fontWeight: 800,
              letterSpacing: '-0.5px',
              mb: 1,
              background: 'linear-gradient(45deg, #2196F3, #FF4081)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: '0 0 30px rgba(33, 150, 243, 0.3)',
            }}
          >
            Join JMF Hosting
          </Typography>
          <Typography 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.9)',
              fontSize: '1.1rem',
              mb: 4,
              fontWeight: 400,
            }}
          >
            Create an account to manage your game servers
          </Typography>
        </Box>

        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            maxWidth: '400px',
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Button
              fullWidth
              startIcon={<GoogleIcon />}
              onClick={() => handleOAuth('google')}
              disabled={loading}
              sx={{
                mb: 2,
                py: 1.5,
                px: 2,
                color: '#fff',
                fontSize: '1rem',
                fontWeight: 500,
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '8px',
                background: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderColor: '#fff',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)'
                }
              }}
            >
              Sign up with Google
            </Button>
            <Button
              fullWidth
              startIcon={<GitHubIcon />}
              onClick={() => handleOAuth('github')}
              disabled={loading}
              sx={{
                py: 1.5,
                px: 2,
                color: '#fff',
                fontSize: '1rem',
                fontWeight: 500,
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '8px',
                background: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderColor: '#fff',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)'
                }
              }}
            >
              Sign up with GitHub
            </Button>
          </Box>

          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 3,
              '&::before, &::after': {
                content: '""',
                flex: 1,
                borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
              }
            }}
          >
            <Typography 
              sx={{ 
                px: 2, 
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '0.9rem',
                fontWeight: 500,
              }}
            >
              or register with email
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              disabled={loading}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: '#fff',
                  fontSize: '1.1rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: '8px',
                  height: '56px',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#2196F3',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                },
              }}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              disabled={loading}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: '#fff',
                  fontSize: '1.1rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: '8px',
                  height: '56px',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#2196F3',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                },
              }}
            />
          </Box>

          <TextField
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            disabled={loading}
            required
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                fontSize: '1.1rem',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                borderRadius: '8px',
                height: '56px',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2196F3',
                  borderWidth: '2px',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '1.1rem',
                fontWeight: 500,
              },
            }}
          />

          <TextField
            fullWidth
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            disabled={loading}
            required
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                fontSize: '1.1rem',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                borderRadius: '8px',
                height: '56px',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2196F3',
                  borderWidth: '2px',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '1.1rem',
                fontWeight: 500,
              },
            }}
          />

          <TextField
            fullWidth
            label="Password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            disabled={loading}
            required
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                fontSize: '1.1rem',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                borderRadius: '8px',
                height: '56px',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2196F3',
                  borderWidth: '2px',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '1.1rem',
                fontWeight: 500,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            disabled={loading}
            required
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                fontSize: '1.1rem',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                borderRadius: '8px',
                height: '56px',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2196F3',
                  borderWidth: '2px',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '1.1rem',
                fontWeight: 500,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {passwordStrength > 0 && (
            <Box sx={{ mb: 2 }}>
              <LinearProgress 
                variant="determinate" 
                value={passwordStrength} 
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: passwordStrength < 60 ? '#FF4081' : '#2196F3'
                  }
                }}
              />
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.7)',
                  mt: 0.5,
                  display: 'block'
                }}
              >
                Password strength: {passwordStrength}%
              </Typography>
              <Box sx={{ mt: 1 }}>
                {Object.entries(passwordCriteria).map(([key, met]) => (
                  <Typography
                    key={key}
                    variant="caption"
                    sx={{
                      display: 'block',
                      color: met ? '#4CAF50' : 'rgba(255, 255, 255, 0.5)',
                    }}
                  >
                    {met ? '✓' : '○'} {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}

          <Button
            type="submit"
            fullWidth
            disabled={loading || passwordStrength < 60}
            sx={{
              mt: 3,
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 600,
              background: 'linear-gradient(45deg, #2196F3, #FF4081)',
              color: '#fff',
              borderRadius: '8px',
              '&:hover': {
                background: 'linear-gradient(45deg, #1976D2, #D81B60)',
              },
              '&.Mui-disabled': {
                background: 'rgba(255, 255, 255, 0.12)',
                color: 'rgba(255, 255, 255, 0.3)',
              }
            }}
          >
            {loading ? 'Creating Account...' : 'Create Account'}
          </Button>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography sx={{ fontSize: '0.9rem', color: 'rgba(255, 255, 255, 0.7)' }}>
              Already have an account?{' '}
              <Link 
                component={RouterLink} 
                to="/auth/login" 
                sx={{ 
                  color: '#2196F3',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Sign in here
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default RegisterPage;
