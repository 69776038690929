import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './context/AuthContext';

// Public Pages
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import OAuthCallback from './pages/auth/OAuthCallback';
import EmailVerification from './pages/auth/EmailVerification';
import VerifyEmail from './pages/auth/VerifyEmail';
import Store from './pages/Store/Store';
import GamePlans from './pages/Store/GamePlans';
import Home from './pages/Home';
import Features from './pages/Features';
import Panel from './pages/Panel';

// Protected Pages
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/dashboard/Profile';
import Settings from './pages/dashboard/Settings';
import Billing from './pages/dashboard/Billing';
import Servers from './pages/dashboard/Servers';

// Admin Pages
import AdminDashboard from './pages/admin/Dashboard';
import AdminUsers from './pages/admin/Users';
import AdminServers from './pages/admin/Servers';

// Legal Pages
import Terms from './pages/legal/Terms';
import Privacy from './pages/legal/Privacy';
import SecurityPolicy from './pages/legal/SecurityPolicy';

// Layouts
import MainLayout from './components/layout/MainLayout';
import DashboardLayout from './components/layout/DashboardLayout';

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" />;
};

const AdminRoute = () => {
  const { isAuthenticated, user } = useAuth();
  return isAuthenticated && user?.role === 'admin' ? <Outlet /> : <Navigate to="/auth/login" />;
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes with Main Layout (includes Navbar and Footer) */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />
        <Route path="/auth/oauth/callback" element={<OAuthCallback />} />
        
        {/* Email verification via link in email (automatic verification) */}
        <Route path="/auth/EmailVerification" element={<EmailVerification />} />
        
        {/* Email verification via code entry (manual verification) */}
        <Route path="/auth/verify-email" element={<VerifyEmail />} />
        
        <Route path="/store" element={<Store />} />
        <Route path="/store/plans/:gameId" element={<GamePlans />} />
        
        {/* Game Server Panel */}
        <Route path="/panel" element={<Panel />} />
        
        {/* Legal Pages */}
        <Route path="/legal/terms" element={<Terms />} />
        <Route path="/legal/privacy" element={<Privacy />} />
        <Route path="/legal/security" element={<SecurityPolicy />} />
        
        {/* Protected Routes with Main Layout (using your preferred layout with navbar and footer) */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/servers" element={<Servers />} />
        </Route>
      </Route>

      {/* Admin Routes */}
      <Route element={<AdminRoute />}>
        <Route element={<MainLayout />}>
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/servers" element={<AdminServers />} />
        </Route>
      </Route>

      {/* Catch-all route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
