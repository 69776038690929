import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Chip,
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
  Divider
} from '@mui/material';
import {
  PlayArrow as StartIcon,
  Stop as StopIcon,
  Refresh as RestartIcon,
  Settings as SettingsIcon,
  MoreVert as MoreIcon,
  SportsEsports as GameIcon,
  Memory as MemoryIcon,
  Storage as StorageIcon,
  People as PlayersIcon
} from '@mui/icons-material';
import { serverService } from '../../services/api';

const ServerStatusCard = ({ server, onRefresh }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAction = async (action) => {
    try {
      setLoading(true);
      switch (action) {
        case 'start':
          await serverService.startServer(server.id);
          break;
        case 'stop':
          await serverService.stopServer(server.id);
          break;
        case 'restart':
          await serverService.restartServer(server.id);
          break;
        default:
          break;
      }
      if (onRefresh) {
        await onRefresh();
      }
    } catch (error) {
      console.error(`Failed to ${action} server:`, error);
    } finally {
      setLoading(false);
      handleMenuClose();
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'running':
        return '#4CAF50';
      case 'stopped':
        return '#f44336';
      case 'starting':
      case 'stopping':
        return '#FFC107';
      default:
        return '#9e9e9e';
    }
  };

  const getStatusText = (status) => {
    switch (status?.toLowerCase()) {
      case 'running':
        return 'Online';
      case 'stopped':
        return 'Offline';
      case 'starting':
        return 'Starting...';
      case 'stopping':
        return 'Stopping...';
      default:
        return 'Unknown';
    }
  };

  const getMemoryUsage = () => {
    const used = server.memory_usage || 0;
    const total = server.memory_mb || 1024;
    const percentage = (used / total) * 100;
    return {
      used: Math.round(used),
      total,
      percentage: Math.round(percentage)
    };
  };

  const memoryUsage = getMemoryUsage();

  return (
    <Card 
      sx={{ 
        height: '100%',
        background: 'rgba(255, 255, 255, 0.05)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)'
        }
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <GameIcon sx={{ color: getStatusColor(server.status), mr: 1 }} />
            <Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
              {server.name}
            </Typography>
          </Box>
          <Box>
            <Chip
              size="small"
              label={getStatusText(server.status)}
              sx={{
                backgroundColor: `${getStatusColor(server.status)}20`,
                color: getStatusColor(server.status),
                fontWeight: 500,
                mr: 1
              }}
            />
            <IconButton
              size="small"
              onClick={handleMenuClick}
              sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 0.5 }}>
            Game: {server.game_type}
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            Version: {server.version}
          </Typography>
        </Box>

        <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <PlayersIcon sx={{ color: '#4CAF50', mr: 1, fontSize: 20 }} />
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              {server.player_count || 0} / {server.max_players || 20} Players
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <MemoryIcon sx={{ color: '#2196F3', mr: 1, fontSize: 20 }} />
            <Box sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                  Memory Usage
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {memoryUsage.used}MB / {memoryUsage.total}MB
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={memoryUsage.percentage}
                sx={{
                  height: 6,
                  borderRadius: 1,
                  backgroundColor: 'rgba(33, 150, 243, 0.1)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#2196F3'
                  }
                }}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StorageIcon sx={{ color: '#FF4081', mr: 1, fontSize: 20 }} />
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              {server.storage_usage || 0}GB / {server.disk_gb}GB Storage
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate(`/servers/${server.id}`)}
            sx={{
              flex: 1,
              background: 'linear-gradient(45deg, #2196F3, #1976D2)',
              color: '#fff',
              '&:hover': {
                background: 'linear-gradient(45deg, #1976D2, #1565C0)'
              }
            }}
          >
            Manage
          </Button>
          <Tooltip title="Server Settings">
            <IconButton
              size="small"
              onClick={() => navigate(`/servers/${server.id}/settings`)}
              sx={{ 
                color: 'rgba(255, 255, 255, 0.7)',
                '&:hover': { color: '#fff' }
              }}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </CardContent>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            backgroundColor: '#1a1a1a',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            '& .MuiMenuItem-root': {
              color: '#fff',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }
          }
        }}
      >
        <MenuItem
          onClick={() => handleAction('start')}
          disabled={server.status === 'running' || loading}
        >
          <StartIcon sx={{ mr: 1 }} /> Start Server
        </MenuItem>
        <MenuItem
          onClick={() => handleAction('stop')}
          disabled={server.status === 'stopped' || loading}
        >
          <StopIcon sx={{ mr: 1 }} /> Stop Server
        </MenuItem>
        <MenuItem
          onClick={() => handleAction('restart')}
          disabled={server.status === 'stopped' || loading}
        >
          <RestartIcon sx={{ mr: 1 }} /> Restart Server
        </MenuItem>
        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
        <MenuItem onClick={() => navigate(`/servers/${server.id}/console`)}>
          View Console
        </MenuItem>
        <MenuItem onClick={() => navigate(`/servers/${server.id}/backups`)}>
          Manage Backups
        </MenuItem>
        <MenuItem onClick={() => navigate(`/servers/${server.id}/mods`)}>
          Mod Manager
        </MenuItem>
      </Menu>

      {loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#2196F3'
            }
          }}
        />
      )}
    </Card>
  );
};

export default ServerStatusCard; 