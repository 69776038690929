import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Button, 
  Container, 
  Typography, 
  Box,
  Grid,
  useTheme,
  Chip
} from '@mui/material';
import { motion } from 'framer-motion';
import '../styles/Home.css';
import GameCard from '../components/games/GameCard';
import StatCard from '../components/stats/StatCard';
import { useInView } from 'react-intersection-observer';

// Import icons for features
import { 
  Bolt as BoltIcon,
  Memory as MemoryIcon,
  Security as SecurityIcon,
  Support as SupportIcon 
} from '@mui/icons-material';

const videos = [
  { src: '/videos/unturned.mp4', type: 'video/mp4' },
  { src: '/videos/minecraft.mp4', type: 'video/mp4' },
  { src: '/videos/rust.mp4', type: 'video/mp4' }
];

// Add this new component before the Home component
const AnimatedButton = ({ children, onClick, ...props }) => {
  const theme = useTheme();
  
  // Create keyframes dynamically using the theme
  React.useEffect(() => {
    const keyframes = `
      @keyframes glow {
        from {
          text-shadow: 
            0 0 7px #fff,
            0 0 10px #fff,
            0 0 21px #fff,
            0 0 42px ${theme.palette.primary.main},
            0 0 82px ${theme.palette.primary.main},
            0 0 92px ${theme.palette.primary.main};
        }
        to {
          text-shadow: 
            0 0 10px #fff,
            0 0 20px #fff,
            0 0 30px #fff,
            0 0 60px ${theme.palette.primary.main},
            0 0 100px ${theme.palette.primary.main},
            0 0 110px ${theme.palette.primary.main};
        }
      }
      @keyframes pulse {
        0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(3px);
        }
        100% {
          transform: translateX(0);
        }
      }
    `;

    const style = document.createElement('style');
    style.textContent = keyframes;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [theme.palette.primary.main]);

  return (
    <Button
      variant="contained" 
      color="primary"
      size="large"
      onClick={onClick}
      sx={{ 
        py: 2, 
        px: 4,
        fontSize: '1.1rem',
        borderRadius: 2,
        textTransform: 'none',
        background: theme => theme.palette.primary.main,
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 4,
          '& .button-text': {
            transform: 'translateY(-100%)',
          },
          '& .button-text-hover': {
            transform: 'translateY(0)',
          }
        },
        transition: 'all 0.3s ease'
      }}
      {...props}
    >
      <Box sx={{ 
        position: 'relative', 
        height: '1.5em',
        overflow: 'hidden'
      }}>
        <Box
          className="button-text"
          sx={{
            position: 'relative',
            transform: 'translateY(0)',
            transition: 'transform 0.3s ease'
          }}
        >
          {children}
        </Box>
        <Box
          className="button-text-hover"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: 'translateY(100%)',
            transition: 'transform 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            color: '#fff',
            textShadow: `
              0 0 7px #fff,
              0 0 10px #fff,
              0 0 21px #fff,
              0 0 42px ${theme.palette.primary.main},
              0 0 82px ${theme.palette.primary.main},
              0 0 92px ${theme.palette.primary.main}
            `,
            animation: 'glow 1s ease-in-out infinite alternate'
          }}
        >
          <span>GO</span> 
          <span style={{ 
            fontSize: '1.2em',
            animation: 'pulse 1s ease-in-out infinite'
          }}>→</span>
        </Box>
      </Box>
    </Button>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [scrollProgress, setScrollProgress] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);
  const [featuresRef, featuresInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}`;

      setScrollProgress(scroll);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleVideoEnd = () => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, []);

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <>
      <motion.div
        className="scroll-progress"
        style={{ scaleX: scrollProgress }}
      />
      <Box sx={{ width: '100%', minHeight: '100vh' }}>
        <div className="home-container">
          {/* Hero Section */}
          <motion.section 
            className="hero-section section-transition"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Box
              component="video"
              ref={videoRef}
              autoPlay
              muted
              playsInline
              preload="auto"
              poster="/images/video-poster.jpg"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: -1,
              }}
            >
              <source src={videos[currentVideoIndex].src} type={videos[currentVideoIndex].type} />
            </Box>

            <motion.div 
              className="hero-content"
              {...fadeIn}
              transition={{ delay: 0.3 }}
              sx={{
                position: 'relative',
                zIndex: 2,
              }}
            >
              <div className="location-badge">
                <span>🌎 Multiple Locations Available</span>
              </div>
              
              <h1 className="hero-title">
                JMFHosting
              </h1>
              
              <p className="hero-subtitle">
                High-performance servers with instant setup, 24/7 support, and a 99.9% uptime guarantee
              </p>
              
              <div className="hero-cta">
                <AnimatedButton onClick={() => navigate('/store')}>
                  View Plans
                </AnimatedButton>
              </div>
            </motion.div>
          </motion.section>

          {/* Features Section */}
          <motion.section 
            ref={featuresRef}
            className={`features-section section-transition ${featuresInView ? 'visible' : ''}`}
          >
            <Container maxWidth="lg" disableGutters>
              <div className="section-content">
                <Box sx={{ textAlign: 'center', mb: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                  >
                    <Chip 
                      label="Amazingly Perfect" 
                      sx={{ 
                        mb: 2,
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        color: 'white',
                        backdropFilter: 'blur(10px)'
                      }} 
                    />
                    <Typography 
                      variant="h2" 
                      component="h2"
                      sx={{ 
                        color: 'white',
                        fontWeight: 'bold',
                        mb: 6
                      }}
                    >
                      Premium Features
                    </Typography>
                  </motion.div>
                </Box>

                <div className="responsive-grid">
                  {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} md={3} key={feature.title}>
                      <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        viewport={{ once: true }}
                      >
                        <Box
                          sx={{
                            textAlign: 'center',
                            p: 3,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            position: 'relative',
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              borderRadius: '8px',
                              background: 'rgba(0, 0, 255, 0.1)',
                              filter: 'blur(15px)',
                              opacity: 0,
                              transition: 'opacity 0.3s ease',
                              zIndex: -1
                            },
                            '&:hover': {
                              '&::before': {
                                opacity: 1
                              }
                            }
                          }}
                        >
                          <Box
                            sx={{
                              width: 80,
                              height: 80,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '50%',
                              backgroundColor: 'rgba(52, 211, 153, 0.1)',
                              position: 'relative',
                              mb: 2,
                              transition: 'all 0.3s ease',
                              '&::before': {
                                content: '""',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                background: 'rgba(52, 211, 153, 0.15)',
                                filter: 'blur(15px)',
                                opacity: 0,
                                transition: 'opacity 0.3s ease'
                              },
                              '&:hover': {
                                transform: 'translateY(-5px)',
                                '&::before': {
                                  opacity: 1
                                },
                                '& .feature-icon': {
                                  transform: 'scale(1.1)',
                                  color: '#4ade80'
                                }
                              }
                            }}
                          >
                            <feature.icon 
                              className="feature-icon"
                              sx={{ 
                                fontSize: 40, 
                                color: '#34D399',
                                transition: 'all 0.3s ease'
                              }} 
                            />
                          </Box>
                          <Typography 
                            variant="h5" 
                            component="h3"
                            sx={{ 
                              color: 'white',
                              fontWeight: 'bold',
                              mb: 1
                            }}
                          >
                            {feature.title}
                          </Typography>
                          <Typography 
                            variant="body1"
                            sx={{ 
                              color: 'rgba(255,255,255,0.7)',
                              lineHeight: 1.7
                            }}
                          >
                            {feature.description}
                          </Typography>
                        </Box>
                      </motion.div>
                    </Grid>
                  ))}
                </div>
              </div>
            </Container>
          </motion.section>

          <div className="section-divider" />

          {/* Server Node Map Section */}
          <section className="node-map-section section-transition">
            <Container maxWidth="lg" disableGutters>
              <div className="section-content">
                <Box sx={{ textAlign: 'center', mb: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                  >
                    <Chip 
                      label="Global Network" 
                      sx={{ 
                        mb: 2,
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        color: 'white',
                        backdropFilter: 'blur(10px)'
                      }} 
                    />
                    <Typography 
                      variant="h2" 
                      component="h2"
                      sx={{ 
                        color: 'white',
                        fontWeight: 'bold',
                        mb: 2
                      }}
                    >
                      Our Server Locations
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: 'rgba(255,255,255,0.7)',
                        maxWidth: '800px',
                        mx: 'auto',
                        mb: 6
                      }}
                    >
                      Strategic server locations worldwide ensuring low latency and optimal gaming experience
                    </Typography>
                  </motion.div>
                </Box>

                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '600px',
                    background: 'rgba(0,0,0,0.3)',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    border: '1px solid rgba(255,255,255,0.1)',
                    backdropFilter: 'blur(10px)',
                    my: 4
                  }}
                >
                  {/* World Map Background */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '90%',
                      height: '90%',
                      opacity: 1,
                      background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${process.env.PUBLIC_URL}/images/world-map.png)`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      filter: 'brightness(1.2) contrast(1.2)',
                    }}
                  />

                  {/* Server Node Points */}
                  {serverNodes.map((node, index) => (
                    <motion.div
                      key={node.id}
                      initial={{ scale: 0, opacity: 0 }}
                      whileInView={{ scale: 1, opacity: 1 }}
                      transition={{ delay: index * 0.1, duration: 0.5 }}
                      style={{
                        position: 'absolute',
                        left: `${node.x}%`,
                        top: `${node.y}%`,
                      }}
                    >
                      <Box
                        sx={{
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          backgroundColor: '#34D399',
                          boxShadow: '0 0 20px #34D399',
                          position: 'relative',
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            backgroundColor: '#34D399',
                            animation: 'pulse 2s infinite',
                            opacity: 0.5,
                          },
                          '@keyframes pulse': {
                            '0%': {
                              transform: 'scale(1)',
                              opacity: 0.5,
                            },
                            '100%': {
                              transform: 'scale(3)',
                              opacity: 0,
                            },
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          position: 'absolute',
                          color: 'white',
                          fontSize: '0.8rem',
                          whiteSpace: 'nowrap',
                          transform: 'translateX(-50%)',
                          top: '20px',
                        }}
                      >
                        {node.location}
                      </Typography>
                    </motion.div>
                  ))}
                </Box>

                {/* Server Stats */}
                <Grid container spacing={3} sx={{ mt: 4 }}>
                  {serverStats.map((stat, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1, duration: 0.5 }}
                        viewport={{ once: true }}
                      >
                        <Box
                          sx={{
                            textAlign: 'center',
                            p: 3,
                            background: 'rgba(255,255,255,0.05)',
                            borderRadius: '12px',
                            border: '1px solid rgba(255,255,255,0.1)',
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              color: '#34D399',
                              fontWeight: 'bold',
                              mb: 1
                            }}
                          >
                            {stat.value}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'rgba(255,255,255,0.7)'
                            }}
                          >
                            {stat.label}
                          </Typography>
                        </Box>
                      </motion.div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Container>
          </section>

          <div className="section-divider" />

          {/* Game Showcase Section */}
          <section className="games-section section-transition">
            <Container maxWidth="lg" disableGutters>
              <div className="section-content">
                <Box sx={{ textAlign: 'center', mb: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                  >
                    <Chip 
                      label="Game Hosting" 
                      sx={{ 
                        mb: 2,
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        color: 'white',
                        backdropFilter: 'blur(10px)'
                      }} 
                    />
                    <Typography 
                      variant="h2" 
                      component="h2"
                      sx={{ 
                        color: 'white',
                        fontWeight: 'bold',
                        mb: 6
                      }}
                    >
                      Featured Games
                    </Typography>
                  </motion.div>
                </Box>

                <Grid container spacing={4} justifyContent="center">
                  {games.map((game) => (
                    <Grid item xs={12} sm={6} md={4} key={game.name}>
                      <GameCard game={game} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Container>
          </section>

          <div className="section-divider" />

          {/* Stats Section */}
          <section className="stats-section">
            <Container maxWidth="lg" disableGutters>
              <div className="section-content">
                <div className="responsive-grid">
                  {stats.map((stat, index) => (
                    <StatCard key={index} stat={stat} index={index} />
                  ))}
                </div>
              </div>
            </Container>
          </section>
        </div>
      </Box>
    </>
  );
};

const features = [
  {
    title: 'Instant Server Access',
    description: 'Get your servers up and running in no time, with instant access to high-performance resources tailored for gaming and business applications.',
    icon: BoltIcon
  },
  {
    title: 'High-Performance Infrastructure',
    description: 'Experience seamless operations with our top-of-the-line hardware, engineered for maximum reliability and efficiency in demanding environments.',
    icon: MemoryIcon
  },
  {
    title: 'Advanced Security Measures',
    description: 'Protect your servers from cyber threats with our robust, multi-layered defense system, ensuring uninterrupted service and data integrity.',
    icon: SecurityIcon
  },
  {
    title: 'Timely Technical Assistance',
    description: 'Enjoy prompt and dedicated support from our team of experts, ensuring any issues are resolved quickly and your operations remain uninterrupted.',
    icon: SupportIcon
  }
];

const stats = [
  { value: 'N/A', label: 'Active Players' },
  { value: 'N/A', label: 'Server Uptime' },
  { value: 'N/A', label: 'TPS' },
  { value: 'N/A', label: 'Memory Usage' }
];

const games = [
  {
    name: 'Minecraft',
    backgroundImage: '/images/games/minecraft/background.jpg',
    logoImage: '/images/games/minecraft/logo.png',
    startingPrice: 6.00,
    features: {
      modSupport: true,
      backups: true,
      ddosProtection: true,
      customDomain: true,
      plugins: true,
      ssd: true
    }
  },
  {
    name: 'Project Zomboid',
    backgroundImage: '/images/games/projectzomboid/background.jpg',
    logoImage: '/images/games/projectzomboid/logo.png',
    startingPrice: 12.00,
    features: {
      modSupport: true,
      backups: true,
      ddosProtection: true,
      customDomain: false,
      plugins: false,
      ssd: true
    }
  },
  {
    name: 'Unturned',
    backgroundImage: '/images/games/unturned/background.jpg',
    logoImage: '/images/games/unturned/logo.png',
    startingPrice: 8.00,
    features: {
      modSupport: true,
      backups: true,
      ddosProtection: true,
      customDomain: true,
      plugins: true,
      ssd: true
    }
  },
  {
    name: 'Rust',
    backgroundImage: '/images/games/rust/background.jpg',
    logoImage: '/images/games/rust/logo.png',
    startingPrice: 14.99,
    features: {
      modSupport: true,
      backups: true,
      ddosProtection: true,
      customDomain: true,
      plugins: true,
      ssd: true
    }
  }
];

const serverNodes = [
  { id: 1, location: 'California', x: 30, y: 40 },
  { id: 2, location: 'New York', x: 36, y: 42 }
];

const serverStats = [
  { value: '99.9%', label: 'Uptime Guarantee' },
  { value: '<20ms', label: 'Average Latency' },
  { value: '24/7', label: 'Support Available' },
  { value: '100%', label: 'DDoS Protection' }
];

export default Home; 