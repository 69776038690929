import React from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';

const Servers = () => {
  const theme = useTheme();
  
  return (
    <Box sx={{ 
      flexGrow: 1, 
      width: '100%', 
      pt: 8,
      pb: 4,
      backgroundColor: 'rgba(0,0,0,0.9)'
    }}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" gutterBottom>
          Servers
        </Typography>
        {/* Add servers content here */}
      </Container>
    </Box>
  );
};

export default Servers; 