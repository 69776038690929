import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Navbar from './Navbar';
import Footer from './Footer';

const MainLayout = () => {
  return (
    <>
      <Navbar />
      <Box 
        component="main" 
        className="main-content"
        sx={{
          flex: '1 1 auto',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: '64px', // Height of the navbar
          minHeight: 'calc(100vh - 64px)',
          p: 0,
          mx: 0,
          width: '100%', // Ensure full width
          maxWidth: '100%', // Prevent any max-width constraints
          overflowX: 'hidden' // Prevent horizontal scrolling if content overflows
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};

export default MainLayout; 