import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useAuth } from '../../context/AuthContext';

/**
 * A component that renders a Stripe Buy Button
 * @param {Object} props - Component props
 * @param {string} props.buyButtonId - The Stripe Buy Button ID
 * @param {string} props.publishableKey - The Stripe publishable key
 * @param {string} props.clientReferenceId - Optional client reference ID to attach to the Checkout Session
 * @param {string} props.customerEmail - Optional email to prefill on the payment page
 * @param {string} props.customerSessionClientSecret - Optional client secret for an existing Customer
 * @param {Object} props.sx - Additional styling for the container
 */
const StripeBuyButton = ({ 
  buyButtonId, 
  publishableKey, 
  clientReferenceId,
  customerEmail,
  customerSessionClientSecret,
  sx = {} 
}) => {
  const buttonRef = useRef(null);
  const { user } = useAuth();

  useEffect(() => {
    // Load Stripe Buy Button script
    const loadScript = () => {
      if (!document.querySelector('script[src="https://js.stripe.com/v3/buy-button.js"]')) {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/buy-button.js';
        script.async = true;
        document.body.appendChild(script);
      }
    };
    
    loadScript();

    // Clean up any existing button
    if (buttonRef.current) {
      while (buttonRef.current.firstChild) {
        buttonRef.current.removeChild(buttonRef.current.firstChild);
      }
    }

    // Create the Stripe Buy Button element
    if (buttonRef.current && buyButtonId && publishableKey) {
      const stripeButton = document.createElement('stripe-buy-button');
      stripeButton.setAttribute('buy-button-id', buyButtonId);
      stripeButton.setAttribute('publishable-key', publishableKey);
      
      // Add client reference ID to link the purchase to our user
      if (clientReferenceId) {
        stripeButton.setAttribute('client-reference-id', clientReferenceId);
      } else if (user?.id) {
        stripeButton.setAttribute('client-reference-id', user.id);
      }
      
      // Set customer email if provided or use user's email
      if (customerEmail) {
        stripeButton.setAttribute('customer-email', customerEmail);
      } else if (user?.email) {
        stripeButton.setAttribute('customer-email', user.email);
      }
      
      // Set customer session client secret if provided
      if (customerSessionClientSecret) {
        stripeButton.setAttribute('customer-session-client-secret', customerSessionClientSecret);
      }
      
      // Append the button to our ref
      buttonRef.current.appendChild(stripeButton);
    }

    // Cleanup function
    return () => {
      if (buttonRef.current) {
        while (buttonRef.current.firstChild) {
          buttonRef.current.removeChild(buttonRef.current.firstChild);
        }
      }
    };
  }, [buyButtonId, publishableKey, clientReferenceId, customerEmail, customerSessionClientSecret, user]);

  return (
    <Box 
      ref={buttonRef} 
      sx={{ 
        width: '100%',
        mt: 3,
        ...sx
      }}
    />
  );
};

StripeBuyButton.propTypes = {
  buyButtonId: PropTypes.string.isRequired,
  publishableKey: PropTypes.string.isRequired,
  clientReferenceId: PropTypes.string,
  customerEmail: PropTypes.string,
  customerSessionClientSecret: PropTypes.string,
  sx: PropTypes.object
};

export default StripeBuyButton; 