import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress, Container, Button } from '@mui/material';
import { useAuth } from '../context/AuthContext';

/**
 * Panel redirect page
 * This page redirects users to the Pterodactyl panel at panel.jmfhosting.com
 */
const Panel = () => {
  const { isAuthenticated } = useAuth();
  
  useEffect(() => {
    // Redirect to the panel after a short delay
    const timer = setTimeout(() => {
      window.location.href = 'https://panel.jmfhosting.com';
    }, 3000);
    
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', py: 8 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Game Server Panel
      </Typography>
      
      <Typography variant="h5" sx={{ mb: 4 }}>
        Redirecting you to the JMF Hosting game server control panel...
      </Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <CircularProgress size={60} />
      </Box>
      
      <Typography variant="body1" paragraph>
        If you are not redirected automatically, please click the button below:
      </Typography>
      
      <Button 
        variant="contained" 
        size="large" 
        href="https://panel.jmfhosting.com"
        sx={{ py: 1.5, px: 4, fontSize: '1.1rem' }}
      >
        Go to Game Panel
      </Button>
      
      {!isAuthenticated && (
        <Typography variant="body2" sx={{ mt: 4, color: 'text.secondary' }}>
          Note: You may need to log in to access the game server panel.
        </Typography>
      )}
    </Container>
  );
};

export default Panel; 